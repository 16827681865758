import { ReactComponent as GoogleSvg } from "./t.svg";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./inboxplacement.css";
import { useSearchParams } from "react-router-dom";
import jwt from "jwt-decode";
import CryptoJS from "crypto-js";
import cookie from "cookiejs";
import React, { useState } from "react";
import { useEffect } from "react";
import { ReactComponent as Refresh } from "./refresh.svg";
import RefreshIcon from "@mui/icons-material/Refresh";
import { CardContent } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import SearchIcon from "@mui/icons-material/Search";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
    Box,
    Grid,
    Button,
    Paper,
    InputBase,
    TableRow,
    TableCell,
    Typography,
    Stack,
    CircularProgress,
    Snackbar,
    Alert,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    MenuItem,
    MenuList,
    Checkbox,
    TextField,
    InputLabel,
    TableContainer,
    TableHead,
    TableBody,
    Table,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import ButtonGroup from "@mui/material/ButtonGroup";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Progressbar from "../Progress_bar/Progress_bar";
import DeleteIcon from "@mui/icons-material/Delete";
import { tooltipClasses } from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import ScheduleIcon from "@mui/icons-material/Schedule";

import EditIcon from "@mui/icons-material/Edit";
import { getTTFB } from "web-vitals";
import outlookImg from "../../img/icons/outlook.png";
import { OutletOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    })
);
// import Button from "@mui/joy/Button";

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const secretString = process.env.REACT_APP_SECRET_STRING;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#004aad",
        color: theme.palette.common.white,
        fontSize: 18,
        fontWeight: "bold",
        textAlign: "center",
        padding: "10px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        textAlign: "center",
        padding: "10px",
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        
    },
    "&:hover": {
     
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

function InboxPlacement() {
    const [date, setDate] = React.useState(dayjs());
    const [bt, setBt] = useState("+ Add new");
    const [alignment, setAlignment] = React.useState("SMTP");
    const [buttonLoading, setButtonLoading] = useState(false);
    const [users, setUsers] = useState(true);
    const [loading, setLoading] = useState(false);
    const [manual, setManual] = useState(true);
    const [auto, setAuto] = useState(false);
    const [alignment1, setAlignment1] = React.useState("manual");
    const [code, setCode] = useState("");
    const [receiverEmail, setReceiveremail] = useState("");
    const [main, setMain] = useState([]);
    const [emails, setEmails] = useState([]);
    const [pagination, setPagination] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalpage] = useState();
    const [successMessage, setSuccessMessage] = React.useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [send, setSend] = useState(true);
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [port, setPort] = useState("587");
    const [host, setHost] = useState("smtp.gmail.com");
    const [smtp, setSmtp] = useState(true);
    const [oauth, setOauth] = useState(false);
    const [add, setAdd] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = useState("");
    const token = searchParams.get("code");
    const scope = searchParams.get("scope");
    const user = jwt(cookie.get("token"));
    const [deleteIpDialougeOpen, setdDeleteIpDialougeOpen] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState([]);
    const [Loader, setLoader] = useState(false);
    const [getScoreExecuted, setGetScoreExecuted] = useState(false);
    const [Close, setClose] = useState(false);
    const [dialog, setDialog] = useState(false);
    const [dialog1, setDialog1] = useState(false);
    const [dialogday, setDialogday] = useState(false);
    const [tryEdit, setTryEdit] = useState(false);
    const [editId, setEditId] = useState("");
    const [subject, setSubject] = useState("");
    const [body, setBody] = useState("");
    const [subjectError, setSubjectError] = useState("");
    const [scheduleUser, setScheduleUser] = useState({});
    const [currentUser, setCurrentUser] = useState(null);
    const [googleToken, setGoogleToken] = useState(true);
    const [bulkData, setBulkData] = useState([]);

    const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const scheduleFormat = { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 };

    const handleScheduleUpdate = (index) => {
        if (scheduleFormat[index]) {
            scheduleFormat[index] = 0;
        } else {
            scheduleFormat[index] = 1;
        }
        // console.log('schedule', scheduleFormat)
    };

    function getDayName(index) {
        switch (index) {
            case "0":
                return "Monday";
            case "1":
                return "Tuesday";
            case "2":
                return "Wednesday";
            case "3":
                return "Thursday";
            case "4":
                return "Friday";
            case "5":
                return "Saturday";
            case "6":
                return "Sunday";
        }
    }

    const handleUpdate = () => {
        // Uncomment this part when you are ready to send data to the server
        console.log("hello sumbit");
        // console.log('currentUser',email[currentUser])
        // console.log('user',emails[currentUser].user)
        console.log("BULKDAT", bulkData);
        setButtonLoading(true);
        // return;
        fetch(`${REACT_APP_SERVER_URL}/UpdateSchedule`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                user: bulkData,
                schedule: bulkData.length > 1 ? scheduleFormat : scheduleUser,
            }),
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setButtonLoading(false);
                if (data.status === 200) {
                    setDialog1(false);
                    setDialogday(false);
                    setSuccessSnackbarOpen(true);
                    setSuccessMessage("Schedule Updated Successfully");
                    getEmails(date, page);
                    setBulkData([]);
                }
                console.log(data);
            })
            .catch((error) => {
                console.error("Error updating schedule:", error);
                setButtonLoading(false); // Ensure loading indicator is stopped in case of error
            });
    };

    const handleCheckboxChange = (day) => {
        setScheduleUser((prevState) => ({
            ...prevState,
            [day]: prevState[day] ? 0 : 1, // Toggle the value between 0 and 1
        }));
    };
    useEffect(() => {
        // Callback lo(gic here
        console.log("State updated:", scheduleUser);
    }, [scheduleUser]);
    const ScheduleClick = () => {
        setDialog1(true);
    };
    const dayScheduleClick = () => {
        setDialogday(true);
    };

    const handleSubjectChange = (event) => {
        setSubject(event.target.value);
        setSubjectError("");
    };

    const handleBodyChange = (event) => {
        setBody(event.target.value);
    };

    let redirectUrl;
    const ENVIRONMENT = process.env.ENVIRONMENT;
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
    console.log(ENVIRONMENT, REACT_APP_SERVER_URL);
    // Environment = "Dev"
    if (process.env.REACT_APP_SERVER_URL === "http://localhost:3001") {
        redirectUrl = "http://localhost:3000/dashboard/InboxPlacement";
    } else {
        redirectUrl = "https://app.xemailaudit.com/dashboard/InboxPlacement";
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setButtonLoading(true);


        console.log("enters in the block");

        try {
            console.log("enters try block");

            fetch(`${REACT_APP_SERVER_URL}/EditData`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                    Subject: subject,
                    Body: body,
                    user: bulkData,
                }),
            })
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    setTryEdit(false);
                    setButtonLoading(false);

                    if (data.status === 200) {
                        setSuccessSnackbarOpen(true);
                        setSuccessMessage("Inserted Successfully");
                        setBulkData([]);
                    }
                    console.log(data);
                });
        } catch (err) {
            console.log("enters catch block");
            console.log(err, "error here on time of edit");
            setButtonLoading(false);
        }
    };

    const getTextRecord = (event) => {
        let userData = event;

        try {
            console.log("userData", userData);

            fetch(`${REACT_APP_SERVER_URL}/FetchTextData`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                    user: userData,
                }),
            })
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    console.log(data, "data getting");

                    if (data.status === 200) {
                        setBody(data.data.body);
                        setSubject(data.data.subject);
                        setTryEdit(true);
                    }
                });
        } catch (err) {
            console.log(err, "error here on time of edit");
        }
    };

    const dialogClick = () => {
        console.log("clicked");
        setDialog(true);
    };

    const dialogEditClick = () => {
        setTryEdit(true);
    };

    function CloseClick() {
        setClose(false);
        setDialog(false);
    }

    function handleSelectAll(e) {
        if (bulkData.length === emails.length) {
            setBulkData([]);
        } else {
            setBulkData(emails.map((email) => email.user));
        }
    }

    // function handleSelectAll(e) {
    //    if (bulkData.length === emails.length) {
    //      setBulkData([]);
    //      setScheduleUser([]);
    //    } else {
    //      setBulkData(emails.map((email) => email.user));
    //      const selectedSchedules = emails.map((email) => email.schedule);
    //        setScheduleUser(selectedSchedules);
    //        setCurrentUser(email); // Indicate that all emails are selected
    //         // Trigger the scheduling action
    //    }
    //  };

    const handleCheckboxClick = (emailId) => {
        if (bulkData.includes(emailId)) {
            setBulkData(bulkData.filter((id) => id !== emailId));
        } else {
            setBulkData([...bulkData, emailId]);
        }
    };
    // const isBulkData = (emailId) => bulkData.includes(emailId);

    function requestSearch(search) {
        let temp = main;
        let mail = [];
        let str = search.toLowerCase();

        temp.map((word) => {
            let str2 = word.user.toLowerCase();
            if (str2.includes(str) === true) {
                mail.push(word);
            }
        });
        setEmails(mail);
    }

    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessSnackbarOpen(false);
    };

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };

    useEffect(() => {
        getEmails(date, page);
        // redirect(token);
        ranString();
        getMail();
    }, []);

    function redirect(token) {
        if (token) {
            if (scope) {
                setAuto(true);
                setManual(false);
                setAlignment1("auto");
                fetch(`${REACT_APP_SERVER_URL}/auth_callback/?code=${token}&login_user=${user.email}`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    method: "GET",
                    credentials: "include",
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        window.history.replaceState({}, document.title, window.location.pathname);
                        setdDeleteIpDialougeOpen(false);
                        if (data.status === 201) {
                            setErrorSnackbarOpen(true);
                            setErrorMessage(data.msg);
                            return;
                        } else if (data.status === 200) {
                            setSuccessSnackbarOpen(true);
                            setSuccessMessage(data.msg);
                            getEmails(date, page);
                        } else if (data.status === 400) {
                            setSuccessSnackbarOpen(true);
                            setSuccessMessage(data.msg);
                            getEmails(date, page);
                        }
                    });
            } else {
                console.log("outlook hitiitt");
                setAuto(true);
                setManual(false);
                setAlignment1("auto");
                fetch(`${REACT_APP_SERVER_URL}/outlookCallback/?code=${token}&login_user=${user.email}`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    method: "GET",
                    credentials: "include",
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        window.history.replaceState({}, document.title, window.location.pathname);
                        setdDeleteIpDialougeOpen(false);
                        if (data.status === 201) {
                            setErrorSnackbarOpen(true);
                            setErrorMessage(data.msg);
                            return;
                        } else if (data.status === 200) {
                            setSuccessSnackbarOpen(true);
                            setSuccessMessage(data.msg);
                            getEmails(date, page);
                        } else if (data.status === 400) {
                            setSuccessSnackbarOpen(true);
                            setSuccessMessage(data.msg);
                            getEmails(date, page);
                        }
                    });
            }
        }
    }

    function DeleteUserHandle(id) {
        console.log(id, "delted id");
        setLoader(true);

        fetch(`${REACT_APP_SERVER_URL}/deleteAutoMails`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                login_user: user.email,
                user: bulkData,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setLoader(false);
                setdDeleteIpDialougeOpen(false);

                if (data.status === 200) {
                    setSuccessSnackbarOpen(true);
                    setSuccessMessage("Deleted successfully");
                    getEmails(date, page);
                }
            });
    }

    async function getEmails(Date, Page) {
        fetch(`${REACT_APP_SERVER_URL}/getMails?date=${formatDate(Date.$d)}&page=${Page}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
                user: user.email,
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setLoading(false);

                if (data.status === 200) {
                    console.log(data, "data");
                    console.log(data.emails, "data.emails");

                    setMain(data.emails);
                    setEmails(data.emails);
                    setScheduleUser(data.bodySchedule);
                    setTotalpage(data.pages);
                    if (data.pages > 1) {
                        setPagination(true);
                    }
                } else {
                    setErrorSnackbarOpen(true);
                    setErrorMessage("Something went wrong");
                }
            });
    }

    function ranString() {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "XGW-";
        const charactersLength = characters.length;
        for (let i = 0; i < 12; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        setCode(result);
    }

    function getMail() {
        setReceiveremail(`
            ipt@myxleads.com,linda@meetxleads.com,ipt@proxleads.org,niharika@b2cxemailwarmup.com,agarwal@xleadscloud.org
            anurag.johri003@gmail.com,v76457188@gmail.com,shivaniramsagar807@gmail.com,
    brijeshtesting12345@gmail.com,brijeshtesting1234@gmail.com,anurag.johri001@outlook.com,
    xgrowth1@outlook.com,anurag.johri002@outlook.com,
    xgrowth@goxemaildeliver.com,testing@emaildeliveryreport.net,linda.p@xemaildeliverlabs.com,
     xgrowth2@outlook.com,xgrowth@xleadsconsulting.com,slicarain@outlook.com,`);
    }

    function copy(text) {
        navigator.clipboard.writeText(text);
    }

    function refresh() {
        ranString();
    }

    const HandleChange1 = (event, newAlignment) => {
        console.log("alignment", newAlignment);
        if (newAlignment === "manual") {
            setManual(true);
            setAuto(false);
            setAlignment1("manual");
        } else if (newAlignment === "auto") {
            setManual(false);
            setAuto(true);
            setAlignment1("auto");
        }
    };

    function sendMail() {
        if (email === "" || pass === "") {
            setErrorSnackbarOpen(true);
            setErrorMessage("All fields are mandatory");
            return;
        } else {
            setButtonLoading(true);
            fetch(`${REACT_APP_SERVER_URL}/automate_placement`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                    code: code,
                },
                method: "POST",
                credentials: "include",
                body: new URLSearchParams({
                    email: email,
                    pass: pass,
                    port: port,
                    host: host,
                    emails: receiverEmail,
                    login_user: user.email,
                }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.status === 400) {
                        setButtonLoading(false);
                        setErrorSnackbarOpen(true);
                        setErrorMessage(data.msg);
                    } else if (data.status === 201) {
                        setButtonLoading(false);
                        setErrorSnackbarOpen(true);
                        setErrorMessage(data.msg);
                    } else if (data.status === 200) {
                        setButtonLoading(false);
                        setSuccessSnackbarOpen(true);
                        setSuccessMessage(data.msg);
                    }
                });
        }
    }

    const HandleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
        if (newAlignment === "SMTP") {
            setSmtp(true);
            setOauth(false);
        } else if (newAlignment === "Oauth") {
            setSmtp(false);
            setOauth(true);
        }
    };

    function handle() {
        if (add === true) {
            setAdd(false);
            setUsers(true);
            setBt("+ Add new");
            setPagination(true);
        } else {
            setAdd(true);
            setUsers(false);
            setBt("Close");
            setPagination(false);
        }
    }

    function formatDate(date_) {
        const originalDateString = date_;
        const date = new Date(originalDateString);

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");

        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }

    const encryptData = (text) => {
        const data = CryptoJS.AES.encrypt(JSON.stringify(text), "XkhZG4fW2t2W").toString();

        return data;
    };

    function details(user) {
        fetch(`${REACT_APP_SERVER_URL}/reportAutoPlacecment?user=${user}&date=${formatDate(date.$d)}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.status === 200) {
                    window.open(`/auto-test?${encryptData(`email=${user}&date=${formatDate(date.$d)}`)}`, "_blank");
                } else {
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.msg);
                }
            });
    }

    function inboxPercent(label) {
        // console.log(label, 'label printint')

        if (label === null) {
            return 0;
        } else {
            let total = label.length;
            let count = 0;
            label.map((data) => {
                if (data.label === "INBOX") {
                    count += 1;
                }
            });
            return (count / total) * 100;
        }
    }

    function spamPercent(label) {
        if (label === null) {
            return 0;
        } else {
            let total = label.length;
            let count = 0;
            label.map((data) => {
                if (data.label === "SPAM") {
                    count += 1;
                }
            });
            return (count / total) * 100;
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getEmails(date, newPage);
    };

    function changeCode() {
        setTimeout(() => {
            ranString();
        }, 2000);
    }

    const originalUrl =
        "https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fmail.google.com%2F&prompt=consent&response_type=code&client_id=418350088308-5klp840tmjgns3h1ke030rbjg58qhdk0.apps.googleusercontent.com&redirect_uri={redirectUrl}";

    const replaceUrl = originalUrl.replace("{redirectUrl}", redirectUrl);

    function outlookHit(e) {
        console.log("hitting");
        fetch(`${REACT_APP_SERVER_URL}/outlook`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
                user: user.email,
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.status === 200) {
                    window.location.replace(data.data);
                }
            });
    }

    function clickOutlookfuntion() {
        setGoogleToken(false);
        outlookHit();
    }

    console.log(googleToken, "token");

    return (
        <Box sx={{ width: "100%", justifyItems: "center", marginTop: "-40px" }}>
            {tryEdit && (
                <Dialog open={tryEdit} onClose={() => setTryEdit(false)}>
                    <form onSubmit={handleSubmit}>
                        <DialogTitle>
                            <div>
                                Plain Text<Switch></Switch>HTML Text
                            </div>
                        </DialogTitle>
                        <DialogContent sx={{ width: "600px" }}>
                            <div style={{ marginBottom: "1rem" }}>
                                <label style={{ marginBottom: "0.5rem", display: "block", fontSize: "1rem" }}>
                                    Subject:
                                </label>
                                <input
                                    type="text"
                                    id="subject"
                                    value={subject}
                                    onChange={handleSubjectChange}
                                    style={{
                                        width: "100%",
                                        padding: "0.5rem",
                                        fontSize: "1rem",
                                        borderRadius: "4px",
                                        border: "1px solid #ccc",
                                    }}
                                />
                                {subjectError && (
                                    <p style={{ color: "red", fontSize: "0.8rem", marginTop: "0.5rem" }}>
                                        {subjectError}
                                    </p>
                                )}
                            </div>
                            <div>
                                <label
                                    htmlFor="body"
                                    style={{ marginBottom: "0.5rem", display: "block", fontSize: "1rem" }}
                                >
                                    Body:
                                </label>
                                <textarea
                                    id="body"
                                    value={body}
                                    onChange={handleBodyChange}
                                    style={{
                                        height: "333px",
                                        width: "100%",
                                        padding: "0.5rem",
                                        fontSize: "1rem",
                                        borderRadius: "4px",
                                        border: "1px solid #ccc",
                                    }}
                                ></textarea>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setTryEdit(false);
                                    setBulkData([]);
                                }}
                                style={{ marginRight: "1rem" }}
                            >
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained" color="primary">
                                {buttonLoading ? <CircularProgress size="1.5rem" color="inherit" /> : "Sumbit"}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            )}
            {
                <Dialog
                    PaperProps={{
                        style: { borderRadius: "10px" },
                    }}
                    open={dialog1}
                >
                    <div style={{ width: "450px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <DialogTitle id="alert-dialog-title">
                            <div>
                                {scheduleUser &&
                                    Object.keys(scheduleUser).map((day, i) => (
                                        <MenuList>
                                            <MenuItem key={i} onClick={() => handleCheckboxChange(day)}>
                                                <input
                                                    type="checkbox"
                                                    checked={scheduleUser[day] === 1}
                                                    style={{ width: "20px", height: "15px", cursor: "pointer" }}
                                                />
                                                <label>
                                                    {getDayName(day)}
                                                    {scheduleUser[day] === 1}
                                                </label>
                                            </MenuItem>
                                        </MenuList>
                                    ))}
                            </div>
                        </DialogTitle>
                        <DialogActions>
                            <Box sx={{ marginTop: "-20px", marginRight: "20px" }}>
                                <Button
                                    variant="contained"
                                    sx={{ margin: "8px" }}
                                    onClick={handleUpdate}
                                    disabled={buttonLoading}
                                >
                                    {buttonLoading ? <CircularProgress size={24} /> : "Apply"}
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{ color: "#fff" }}
                                    color="success"
                                    onClick={() => {
                                        setDialog1(false);
                                        setBulkData([]);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </DialogActions>
                    </div>
                </Dialog>
            }

            {
                <Dialog
                    PaperProps={{
                        style: { borderRadius: "10px" },
                    }}
                    open={dialogday}
                >
                    <div style={{ width: "450px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <DialogTitle id="alert-dialog-title">
                            <div>
                                {daysOfWeek &&
                                    daysOfWeek.map((day, i) => (
                                        <MenuList>
                                            <MenuItem key={i}>
                                                <input
                                                    type="checkbox"
                                                    onChange={() => handleScheduleUpdate(i)}
                                                    style={{ width: "20px", height: "15px", cursor: "pointer" }}
                                                />
                                                <label>{day} </label>
                                            </MenuItem>
                                        </MenuList>
                                    ))}
                            </div>
                        </DialogTitle>
                        <DialogActions>
                            <Box sx={{ marginTop: "-20px", marginRight: "20px" }}>
                                <Button
                                    variant="contained"
                                    sx={{ margin: "8px" }}
                                    onClick={handleUpdate}
                                    disabled={buttonLoading}
                                >
                                    {buttonLoading ? <CircularProgress size={24} /> : "Apply"}
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{ color: "#fff" }}
                                    color="success"
                                    onClick={() => {
                                        setDialogday(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </DialogActions>
                    </div>
                </Dialog>
            }

            <Box>
                <Dialog
                    PaperProps={{
                        style: { borderRadius: "20px" },
                    }}
                    open={deleteIpDialougeOpen}
                >
                    <div
                        style={{
                            width: "500px",
                            height: "200px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <DialogTitle id="alert-dialog-title">
                            <p style={{ color: "red", fontWeight: "bold", fontSize: "20px" }}>
                                Are you sure you want to delete account?
                            </p>
                        </DialogTitle>
                        <DialogActions sx={{ mb: 4, float: "right" }}>
                            {Loader ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        DeleteUserHandle(bulkData);
                                        setBulkData([]);
                                    }}
                                    sx={{ color: "#fff" }}
                                    color="success"
                                >
                                    Delete
                                </Button>
                            )}

                            <Button
                                variant="contained"
                                onClick={() => {
                                    setdDeleteIpDialougeOpen(false);
                                    setBulkData([]);
                                }}
                                sx={{ color: "#fff" }}
                                color="error"
                                disabled={false}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </div>
                </Dialog>
                <Box sx={{ textAlign: "center" }}>
                    <span style={{ fontSize: "30px", fontWeight: "1200px", lineHeight: "38px" }}>Inbox Placement</span>
                    <br />
                    <span
                        style={{
                            textAlign: "center",
                            fontSize: "18px",
                            color: "rgba(71, 84, 103, 1)",
                            fontWeight: "400px",
                            lineHeight: "24px",
                        }}
                    >
                        Wondering where your emails land? Gain clarity and optimize your email deliverability with ease.
                        These analyser tools offer real-time insights, indicating whether your emails successfully reach
                        the recipient's inbox or are categorized elsewhere.
                    </span>
                </Box>

                <Box
                    sx={{
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "10px",
                        marginLeft: "100px",
                    }}
                >
                    <ToggleButtonGroup
                        variant="solid"
                        value={alignment1}
                        exclusive
                        onChange={HandleChange1}
                        aria-label="Platform"
                        sx={{ marginBottom: "20px", padding: "10px", width: "30%" }}
                    >
                        <ToggleButton
                            style={{
                                width: "auto",
                                padding: "10px",
                                color: alignment1 === "manual" ? "white" : "black",
                                backgroundColor: alignment1 === "manual" ? "#004aad" : "white",
                            }}
                            value="manual"
                        >
                            Manual
                        </ToggleButton>
                        <ToggleButton
                            style={{
                                width: "auto%",
                                padding: "10px",
                                color: alignment1 === "auto" ? "white" : "black",
                                backgroundColor: alignment1 === "auto" ? "#004aad" : "white",
                            }}
                            value="auto"
                        >
                            Automatic
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>

                {manual && (
                    <Box>
                        <p
                            style={{
                                textAlign: "center",
                                fontSize: "17px",
                                marginTop: "-10px",
                                color: "rgba(71, 84, 103, 1)",
                                fontWeight: "400px",
                                lineHeight: "20px",
                            }}
                        >
                            Manually send a test email to discover its destination. Just follow the provided
                            instructions.
                        </p>
                        <Box sx={{ maxWidth: "1600px", minWidth: "1200px" }}>
                            <section className="card">
                                <div className="insider">
                                    <Box>
                                        <h4>STEP 1: Insert The Provided Code In The Email's Subject</h4>
                                        <ButtonGroup
                                            style={{ width: "auto", marginTop: "-10px" }}
                                            disableElevation
                                            aria-label="Disabled elevation buttons"
                                            sx={{ marginBottom: "10px", borderRadius: "8px" }}
                                            onClick={(e) => {
                                                copy(code);
                                                setSuccessSnackbarOpen(true);
                                                setSuccessMessage("Code copied Successfully");
                                            }}
                                        >
                                            <Button
                                                onClick={refresh}
                                                sx={{
                                                    height: "3.4em",
                                                    backgroundColor: "white",
                                                    border: "1 px solid black",
                                                }}
                                            >
                                                <Refresh></Refresh>
                                            </Button>
                                            <Button
                                                sx={{
                                                    background: "white",
                                                    color: "black",
                                                    fontSize: "20px",
                                                    // padding: "4px",
                                                    width: "90%",
                                                }}
                                            >
                                                {code}
                                            </Button>
                                            <Button sx={{ background: "white", color: "black", padding: "4px" }}>
                                                <FileCopyIcon></FileCopyIcon>
                                            </Button>
                                        </ButtonGroup>
                                    </Box>
                                    <Box sx={{ marginTop: "-15px" }}>
                                        <h4>STEP 2 : Send Your Email To These Addresses</h4>
                                        <ButtonGroup
                                            disableElevation
                                            aria-label="Disabled elevation buttons"
                                            sx={{
                                                marginBottom: "5px",
                                                borderRadius: "8px",
                                                width: "auto",
                                                marginTop: "-10px",
                                            }}
                                            onClick={(e) => {
                                                copy(receiverEmail);
                                                setSuccessSnackbarOpen(true);
                                                setSuccessMessage("Addresses copied Successfully");
                                            }}
                                        >
                                            <Button sx={{ background: "white", color: "black", fontSize: "12px" }}>
                                                {receiverEmail}
                                            </Button>
                                            <Button sx={{ background: "white", color: "black" }}>
                                                <FileCopyIcon></FileCopyIcon>
                                            </Button>
                                        </ButtonGroup>
                                    </Box>

                                    <Box>
                                        <Typography
                                            sx={{
                                                marginTop: "8px",
                                                marginBottom: "15px",
                                                fontSize: "17px",
                                                color: "rgba(71, 84, 103, 1)",
                                                lineHeight: "20px",
                                            }}
                                        >
                                            <span
                                                style={{
                                                    color: "rgba(255, 115, 115, 0.93)",
                                                    fontWeight: 600,
                                                    lineHeight: "20px",
                                                }}
                                            >
                                                NOTE:
                                            </span>{" "}
                                            This code will search for result only for 4 minutes from the sending time,
                                            After that the code will expire and show the Latest Result, So kindly please
                                            first send the code and mail, then search for result.
                                        </Typography>
                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                            <Link
                                                href={`${process.env.REACT_APP_URL}/test/${code.slice(0, code.length)}?executeGetScore=${getScoreExecuted}`}
                                                target="blank"
                                                sx={{
                                                    width: "auto",
                                                    border: "1px solid rgba(0, 0, 0, 0.13)",
                                                    borderRadius: "4px",
                                                    backgroundColor: "#004AAD",
                                                    padding: "5px 10px",
                                                    textDecoration: "none",
                                                    color: "white",
                                                }}
                                                onClick={() => {
                                                    setGetScoreExecuted(true);
                                                    changeCode();
                                                }}
                                            >
                                                GET SCORE
                                            </Link>
                                        </Box>
                                    </Box>
                                </div>
                            </section>
                        </Box>
                    </Box>
                )}
                {auto && (
                    <Box>
                        <Box>
                            <span style={{ fontSize: "20px", fontweight: 600, lineHeight: "30px" }}>
                                Discover Your Emails
                            </span>
                            <p
                                style={{
                                    color: "rgba(71, 84, 103, 1)",
                                    marginTop: "0px",
                                    fontSize: "18px",
                                    fontWeight: "400px",
                                    lineHeight: "20px",
                                }}
                            >
                                Test email domain placement without sending massages.Easily view result tailored for
                                your account.
                            </p>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Box>
                                <Paper
                                    component="form"
                                    sx={{
                                        p: 2,
                                        mr: 2,
                                        display: "flex",
                                        alignItems: "center",
                                        width: "300px",
                                        height: "35px",
                                        boxShadow: "none",
                                        border: "1px solid rgba(71, 84, 103, 1)",
                                        float: "left",
                                        marginBottom: "10px",
                                        marginTop: "10px",
                                        fontsize: "16px",
                                        fontWeight: "700",

                                        boxshadow: "0px 2px 1px #1018280d,",
                                    }}
                                >
                                    <SearchIcon sx={{ color: "#aaa" }} />
                                    <InputBase
                                        sx={{ flex: 1, border: "none" }}
                                        placeholder="Search; keyword, title of email"
                                        onChange={(e) => {
                                            setSearch(e.target.value);
                                            requestSearch(e.target.value);
                                        }}
                                        inputProps={{ "aria-label": "search for projects" }}
                                    />
                                </Paper>
                                {users && (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={["DatePicker"]} sx={{ display: "flex" }}>
                                            <DatePicker
                                                slotProps={{ textField: { size: "small" } }}
                                                value={date}
                                                onChange={(newValue) => {
                                                    setDate(newValue);
                                                    getEmails(newValue, page);
                                                    setPagination(false);
                                                }}
                                                label="Date picker"
                                            />
                                            <Tooltip title="Refresh">
                                                <Button
                                                    variant="outlined"
                                                    sx={{ borderRadius: "40px" }}
                                                    onClick={(e) => {
                                                        getEmails(date, page);
                                                        setPagination(false);
                                                    }}
                                                >
                                                    <RefreshIcon></RefreshIcon>
                                                </Button>
                                            </Tooltip>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                )}
                            </Box>
                            {bulkData && bulkData.length !== 0 ? (
                                <Box
                                    sx={{
                                        marginRight: "20px",
                                        visibility: bulkData.length <= 0 ? "hidden" : "visible",
                                    }}
                                >
                                    <Button
                                        sx={{
                                            width: "auto",
                                            height: "35px",
                                            marginRight: "10px",
                                            border: "1px solid blue",
                                        }}
                                        onClick={dialogEditClick}
                                    >
                                        <EditIcon sx={{ color: "blue" }} />
                                    </Button>

                                    <Button
                                        sx={{
                                            width: "auto",
                                            height: "35px",
                                            border: "1px solid red",
                                            marginRight: "10px",
                                        }}
                                        onClick={() => {
                                            setdDeleteIpDialougeOpen(true);
                                        }}
                                    >
                                        <DeleteIcon sx={{ color: "red" }} />
                                    </Button>

                                    <Button sx={{ border: "1px solid green" }} onClick={dayScheduleClick}>
                                        <ScheduleIcon sx={{ color: "green" }} />
                                    </Button>
                                </Box>
                            ) : (
                                <Button
                                    variant="contained"
                                    onClick={dialogClick}
                                    sx={{
                                        width: "120px",
                                        height: "35px",
                                        textTransform: "none",
                                        fontSize: "15px",
                                        fontWeight: "700",
                                        backgroundColor: "#004aad",
                                        float: "right",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "6px",
                                        margin: "0",
                                    }}
                                >
                                    <AddIcon />
                                    <p style={{alignItems:'center',marginTop:'20px'}}> Add New</p>
                                </Button>
                            )}
                        </Box>
                        <Box sx={{ width: "98.2%", marginTop: "20px" }}>
                            {
                                <Box sx={{}}>
                                    <Box sx={{ width: "100%" }}>
                                        <Dialog
                                            PaperProps={{
                                                style: { borderRadius: "20px" },
                                            }}
                                            open={dialog}
                                        >
                                            <div style={{ width: "100%" }}>
                                                <DialogTitle id="alert-dialog-title">
                                                    <Box className="modal-header">
                                                        <h5> Add New </h5>
                                                        <p>
                                                            {" "}
                                                            Trace email delivery to domains without tests,
                                                            Integrate,run,view-all personalized,{" "}
                                                        </p>
                                                    </Box>
                                                    <Box className="modal-body">
                                                        <div style={{ textAlign: "center" }}>
                                                            <ToggleButtonGroup
                                                                color="primary"
                                                                value={alignment}
                                                                exclusive
                                                                onChange={HandleChange}
                                                                aria-label="Platform"
                                                                sx={{ marginBottom: "20px" }}
                                                            >
                                                                <ToggleButton value="SMTP">SMTP</ToggleButton>
                                                                <ToggleButton value="Oauth">Oauth</ToggleButton>
                                                            </ToggleButtonGroup>
                                                        </div>
                                                        {smtp && (
                                                            <div className="body-grid">
                                                                {
                                                                    <div>
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={7}>
                                                                                <InputLabel htmlFor="email">
                                                                                    Enter email which you want to add
                                                                                </InputLabel>
                                                                                <TextField
                                                                                    // id="email"
                                                                                    // label="email"
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    fullWidth
                                                                                    onChange={(e) =>
                                                                                        setEmail(e.target.value)
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={5}>
                                                                                <InputLabel htmlFor="password">
                                                                                    Enter email password
                                                                                </InputLabel>
                                                                                <TextField
                                                                                    id="password"
                                                                                    // label="Password"
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    type="password"
                                                                                    fullWidth
                                                                                    onChange={(e) => {
                                                                                        setPass(e.target.value);
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={7}>
                                                                                <InputLabel htmlFor="port">
                                                                                    Enter port
                                                                                </InputLabel>
                                                                                <TextField
                                                                                    id="port"
                                                                                    value={port}
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    fullWidth
                                                                                    onChange={(e) =>
                                                                                        setPort(e.target.value)
                                                                                    }
                                                                                ></TextField>
                                                                            </Grid>
                                                                            <Grid item xs={5}>
                                                                                <InputLabel htmlFor="host">
                                                                                    Enter host
                                                                                </InputLabel>
                                                                                <TextField
                                                                                    id="host"
                                                                                    value={host}
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    fullWidth
                                                                                    onChange={(e) =>
                                                                                        setHost(e.target.value)
                                                                                    }
                                                                                ></TextField>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                }
                                                            </div>
                                                        )}
                                                        {oauth && (
                                                            <div className="body-grid">
                                                                <div className="body-icon">
                                                                    <div className="body-google">
                                                                        <div style={{ width: "100%" }}>
                                                                            <GoogleSvg
                                                                                style={{
                                                                                    width: "60px",
                                                                                    height: "60px",
                                                                                    marginTop: "13px",
                                                                                }}
                                                                            ></GoogleSvg>
                                                                        </div>
                                                                        <div className="body-link">
                                                                            <Link
                                                                                href={replaceUrl}
                                                                                style={{ textDecoration: "none" }}
                                                                            >
                                                                                <a className="link">
                                                                                    {" "}
                                                                                    CONNECT WITH GOOGLE
                                                                                </a>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="body-outlook">
                                                                        <div>
                                                                            <img
                                                                                src={outlookImg}
                                                                                style={{
                                                                                    width: "60px",
                                                                                    height: "60px",
                                                                                    marginTop: "13px",
                                                                                }}
                                                                            ></img>
                                                                        </div>
                                                                        <div className="outlooklink">
                                                                            <Link
                                                                                style={{ textDecoration: "none" }}
                                                                                href="https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=613ff4a8-0eea-4557-8cd0-5b42106cc1b5&scope=email%20IMAP.AccessAsUser.All%20Mail.Read%20Mail.ReadBasic%20Mail.Send%20MailboxSettings.Read%20openid%20profile%20User.Read%20offline_access&redirect_uri=https%3A%2F%2Fapp.xemailaudit.com%2Fdashboard%2FInboxPlacement&client-request-id=7b2dbcb3-f11b-4693-a129-869aabeece74&response_mode=query&response_type=code&x-client-SKU=msal.js.node&x-client-VER=1.0.0&x-client-OS=linux&x-client-CPU=x64&client_info=1&prompt=consent"
                                                                            >
                                                                                <a className="outlook-link">
                                                                                    CONNECT WITH OUTLOOK
                                                                                </a>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Box>

                                                    <div className="modal-footer">
                                                        {oauth ? (
                                                            <Box>
                                                                <Button
                                                                    variant="contained"
                                                                    className="cancel-btn-color"
                                                                    onClick={CloseClick}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </Box>
                                                        ) : (
                                                            <>
                                                                <Button
                                                                    variant="contained"
                                                                    className="cancel-btn-color"
                                                                    onClick={CloseClick}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                                {send && (
                                                                    <Button
                                                                        variant="contained"
                                                                        color="success"
                                                                        onClick={sendMail}
                                                                    >
                                                                        {buttonLoading ? (
                                                                            <CircularProgress
                                                                                size="1.5rem"
                                                                                color="inherit"
                                                                            />
                                                                        ) : (
                                                                            "verify & add"
                                                                        )}
                                                                    </Button>
                                                                )}
                                                            </>
                                                        )}

                                                        {/* {score && <Link href={`${process.env.REACT_APP_URL}/auto-test/${code.slice(1, (code.length))}`} target="blank" sx={{ border: 'none', borderRadius: '4px', backgroundColor: '#1976d2', padding: '10px', textDecoration: 'none', color: 'white' }}>GET SCORE</Link>} */}
                                                    </div>
                                                </DialogTitle>
                                            </div>
                                        </Dialog>
                                    </Box>
                                </Box>
                            }
                        </Box>

                        {/* {
            add && <Box>
              {smtp && <div>
                {
                  <section className="Card">
                    <div className="Insider">
                      <span className="text">Enter email which you want to add:</span><input type="text1" id="email" onChange={(e) => { setEmail(e.target.value) }}></input><br></br><br></br>
                      <span className="text">Enter email password:</span><input type="password" id="pass" onChange={(e) => { setPass(e.target.value) }}></input><br></br><br></br>
                      <span className="text">Enter port:</span><select type="number" id="clientid" onChange={(e) => { setPort(e.target.value) }}><option value='993'>993</option><option value='587'>587</option></select>
                      <span className="text">Enter host:</span><select type="text" id="clientsecret" onChange={(e) => { setHost(e.target.value) }}><option value='smtp.google.com'>smtp.google.com</option></select><br></br><br></br>
                    </div>
                    <div className="button">
                      {send && <Button variant="contained" onClick={sendMail}>
                        {buttonLoading ? <CircularProgress size="1.5rem" color="inherit" /> : 'verify & add'}
                      </Button>}
                      {/* {score && <Link href={`${process.env.REACT_APP_URL}/auto-test/${code.slice(1, (code.length))}`} target="blank" sx={{ border: 'none', borderRadius: '4px', backgroundColor: '#1976d2', padding: '10px', textDecoration: 'none', color: 'white' }}>GET SCORE</Link>} 
                    </div>
                  </section>
                }
              </div>
             
              }
              {
                oauth && <div>
                  <div className="oauth">
                    <br></br>
                    <GoogleSvg></GoogleSvg><br></br><br></br>
                    <Link sx={{ border: 'none', borderRadius: '4px', backgroundColor: '#1976d2', padding: '10px', textDecoration: 'none', color: 'white' }} href='https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fmail.google.com%2F&prompt=consent&response_type=code&client_id=418350088308-5klp840tmjgns3h1ke030rbjg58qhdk0.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Faudit.xcampaign.ai%2Fdashboard%2FInboxPlacement'>Connect with OAuth</Link><br></br><br></br>
                  </div>
                </div>
              }
            </Box>
          }  */}

                        {users && (
                            <Box sx={{ marginTop: "-10px" }}>
                                {loading ? (
                                    <Box sx={{ width: "100%", display: "center", justifyContent: "center" }}>
                                        <CircularProgress />
                                        <Typography>Loading ...</Typography>
                                    </Box>
                                ) : emails.length !== 0 ? (
                                    <Box sx={{ marginTop: "30px", marginBottom: "20px" }}>
                                        <Box>
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell>
                                                                <Checkbox
                                                                    checked={bulkData.length === emails.length}
                                                                    onChange={handleSelectAll}
                                                                    className="checkbox-wrap"
                                                                />
                                                            </StyledTableCell>
                                                            <StyledTableCell align="right">Email</StyledTableCell>
                                                            <StyledTableCell align="right">Date</StyledTableCell>
                                                            <StyledTableCell align="right">Inbox</StyledTableCell>
                                                            <StyledTableCell align="right">Spam</StyledTableCell>
                                                            <StyledTableCell align="right">Report</StyledTableCell>
                                                            <StyledTableCell align="right">Actions</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {emails
                                                            ? emails.map((data, i) => (
                                                                  <StyledTableRow key={i}>
                                                                      <StyledTableCell component="th" scope="row">
                                                                          <Checkbox
                                                                              checked={bulkData.includes(data.user)}
                                                                              onChange={() =>
                                                                                  handleCheckboxClick(data.user)
                                                                              }
                                                                          />
                                                                      </StyledTableCell>
                                                                      <StyledTableCell>{data.user}</StyledTableCell>
                                                                      <StyledTableCell>
                                                                          <Typography
                                                                              sx={{
                                                                                  fontSize: "16px",
                                                                                  fontWeight: 500,
                                                                                  lineHeight: "18px",
                                                                                  marginLeft: "10px",
                                                                              }}
                                                                          >
                                                                              {formatDate(date.$d)}
                                                                          </Typography>
                                                                      </StyledTableCell>
                                                                      <StyledTableCell>
                                                                          <div
                                                                              style={{
                                                                                  display: "flex",
                                                                                  alignItems: "center",
                                                                              }}
                                                                          >
                                                                              <Progressbar
                                                                                  bgcolor="#1ab844"
                                                                                  progress={`${Math.round(inboxPercent(data.label))}`}
                                                                                  height={1.5}
                                                                              />
                                                                              <Typography
                                                                                  variant="caption"
                                                                                  component="div"
                                                                                  color="text.secondary"
                                                                                  sx={{
                                                                                      fontWeight: "bolder",
                                                                                      fontSize: "13px",
                                                                                      ml: 1,
                                                                                  }}
                                                                              >
                                                                                  {`${Math.round(inboxPercent(data.label))}%`}
                                                                              </Typography>
                                                                          </div>
                                                                      </StyledTableCell>
                                                                      <StyledTableCell>
                                                                          <div
                                                                              style={{
                                                                                  display: "flex",
                                                                                  alignItems: "center",
                                                                              }}
                                                                          >
                                                                              <Progressbar
                                                                                  bgcolor="red"
                                                                                  progress={`${Math.round(spamPercent(data.label))}`}
                                                                                  height={1.5}
                                                                              />
                                                                              <Typography
                                                                                  variant="caption"
                                                                                  component="div"
                                                                                  color="text.secondary"
                                                                                  sx={{
                                                                                      fontWeight: "bolder",
                                                                                      fontSize: "13px",
                                                                                      ml: 1,
                                                                                      display: "flex",
                                                                                      alignItems: "center",
                                                                                  }}
                                                                              >
                                                                                  {`${Math.round(spamPercent(data.label))}%`}
                                                                              </Typography>
                                                                          </div>
                                                                      </StyledTableCell>
                                                                      <StyledTableCell>
                                                                          <Button
                                                                              variant="outlined"
                                                                              sx={{
                                                                                  height: "36px",
                                                                                  gap: "10px",
                                                                                  border: "1.8px solid blue",
                                                                                  color: "blue",
                                                                                  marginLeft: "10px",
                                                                                  marginRight: "10px",
                                                                              }}
                                                                              onClick={(e) => {
                                                                                  details(data.user);
                                                                              }}
                                                                          >
                                                                              <span
                                                                                  style={{
                                                                                      fontSize: "13px",
                                                                                      fontWeight: "600",
                                                                                      lineHeight: "16.38px",
                                                                                  }}
                                                                              >
                                                                                  see report
                                                                              </span>
                                                                          </Button>
                                                                      </StyledTableCell>
                                                                      <StyledTableCell>
                                                                          <div></div>
                                                                          {bulkData && bulkData.length !== 0 ? (
                                                                              <>
                                                                                  <EditIcon sx={{ color: "pink" }} />
                                                                                  <ScheduleIcon
                                                                                      sx={{ color: "pink" }}
                                                                                  />
                                                                                  <DeleteIcon sx={{ color: "pink" }} />
                                                                              </>
                                                                          ) : (
                                                                              <div
                                                                                  style={{
                                                                                      display: "flex",
                                                                                      justifyContent: "space-around",
                                                                                  }}
                                                                              >
                                                                                  <LightTooltip
                                                                                      title={
                                                                                          <span
                                                                                              style={{
                                                                                                  color: "blue",
                                                                                              }}
                                                                                          >
                                                                                              Edit
                                                                                          </span>
                                                                                      }
                                                                                  >
                                                                                      <EditIcon
                                                                                          sx={{ color: "blue" }}
                                                                                          onClick={() => {
                                                                                              getTextRecord(data.user);
                                                                                              bulkData.push(data.user);
                                                                                          }}
                                                                                      />
                                                                                  </LightTooltip>

                                                                                  <LightTooltip
                                                                                      title={
                                                                                          <span
                                                                                              style={{
                                                                                                  color: "green",
                                                                                              }}
                                                                                          >
                                                                                              Schedule
                                                                                          </span>
                                                                                      }
                                                                                  >
                                                                                      <ScheduleIcon
                                                                                          sx={{ color: "green" }}
                                                                                          onClick={() => {
                                                                                              setScheduleUser(
                                                                                                  data.schedule
                                                                                              );
                                                                                              setCurrentUser(i);
                                                                                              ScheduleClick();
                                                                                              bulkData.push(data.user);
                                                                                          }}
                                                                                      />
                                                                                  </LightTooltip>
                                                                                  <LightTooltip
                                                                                      title={
                                                                                          <span
                                                                                              style={{ color: "red" }}
                                                                                          >
                                                                                              Delete
                                                                                          </span>
                                                                                      }
                                                                                  >
                                                                                      <DeleteIcon
                                                                                          sx={{ color: "red" }}
                                                                                          onClick={() => {
                                                                                              setdDeleteIpDialougeOpen(
                                                                                                  true
                                                                                              );
                                                                                              bulkData.push(data.user);
                                                                                          }}
                                                                                      />
                                                                                  </LightTooltip>
                                                                              </div>
                                                                          )}
                                                                      </StyledTableCell>
                                                                  </StyledTableRow>
                                                              ))
                                                            : ""}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                    </Box>
                                ) : (
                                    <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell>
                                                            <Checkbox className="checkbox-wrap" />
                                                        </StyledTableCell>
                                                        <StyledTableCell>Email</StyledTableCell>
                                                        <StyledTableCell>Date</StyledTableCell>
                                                        <StyledTableCell>Inbox</StyledTableCell>
                                                        <StyledTableCell>Spam</StyledTableCell>
                                                        <StyledTableCell>Report</StyledTableCell>
                                                        <StyledTableCell>Actions</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <StyledTableRow>
                                                        <StyledTableCell>
                                                            <div>
                                                                <Typography sx={{ marginLeft: "-40px" }}>
                                                                    No Reports Found{" "}
                                                                </Typography>
                                                            </div>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                )}
                            </Box>
                        )}
                    </Box>
                )}
                {auto && (
                    <Box>
                        {pagination && (
                            <Stack
                                spacing={2}
                                sx={{
                                    display: "flex",
                                    textAlign: "center",
                                    justifyItems: "center",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                    marginTop: "-5px",
                                    marginRight: "30px",
                                }}
                                onClick={CloseClick}
                            >
                                <Pagination onChange={handleChangePage} count={totalPage} color="primary" />
                            </Stack>
                        )}
                    </Box>
                )}
            </Box>

            <Snackbar open={successSnackbarOpen} autoHideDuration={2000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={errorSnackbarOpen} autoHideDuration={2000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default InboxPlacement;
