import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
    CssBaseline,
    Box,
    Typography,
    CircularProgress,
    Button,
    TextField,
    Snackbar,
    Alert,
    InputAdornment,
    IconButton,
} from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import xaudit1 from "../img/icons/xaudit1.png";
import cookie from "cookiejs";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const mdTheme = createTheme({
    typography: {
        fontFamily: ["League Spartan", "sans-serif"].join(","),
    },
});
const Login = (props) => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [loginLoading, setLoginLoading] = React.useState(false);
    const [sessionExpired, setSessionExpired] = React.useState(state === null ? false : state.sessionExpired);
    const [accountVerifiedMsg, setAccountVerifiedMsg] = React.useState(state === null ? "" : state.msg);
    const [showPassword, setShowPassword] = React.useState(false);
    const [resetPasswordSuccess, setResetPasswordSuccess] = React.useState(state === null ? false : state.success);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const [verification, setVerification] = useState(false);
    React.useEffect(() => {
        let isLogin = cookie.get("token");
        if (isLogin) {
            if (email === "rakesh@saleshive.com") {
                console.log("lies in rakesh");
                navigate("/dashboard/dataDashboard");
            }
            console.log("outside rakesh");
            navigate("/dashboard/InboxPlacement");
        }
    }, [navigate]);
    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };

    function validateEmail(data) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(data);
    }

    function verify() {
        setVerification(true);
    }

    const submitHandler = (e) => {
        e.preventDefault();
        setLoginLoading(true);

        if (!validateEmail(email)) {
            setErrorSnackbarOpen(true);
            setLoginLoading(false);
            setErrorMessage("Enter valid email");
            return;
        } else if (password.length < 6) {
            setErrorSnackbarOpen(true);
            setLoginLoading(false);
            setErrorMessage("Enter valid password");
            return;
        } else {
            console.log(
                new URLSearchParams({
                    email: email,
                    password: password,
                }).toString()
            );
            fetch(`${REACT_APP_SERVER_URL}/login`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "POST",
                credentials: "include",
                body: new URLSearchParams({
                    password: password,
                    email: email,
                }),
            })
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response);
                        setLoginLoading(false);
                        return response.json();
                    } else {
                        setLoginLoading(false);
                        setErrorSnackbarOpen(true);
                        return response.json();
                    }
                })
                .then((data) => {
                    props.User(data.data);
                    console.log(data);
                    if (data.status === 200) {
                        props.User(data.data);
                        // navigate("/dashboard/InboxPlacement")
                        if (email === "rakesh@saleshive.com") {
                            console.log("lies in rakesh");
                            navigate("/dashboard/dataDashboard");
                        } else {
                            console.log("outside rakesh");
                            navigate("/dashboard/InboxPlacement");
                        }
                    }
                    if (data.status === 501) {
                        setErrorMessage(data.msg);
                        verify();
                    } else if (data.status === 201) {
                        setErrorMessage(data.msg);
                    }
                });
        }
    };
    const handleSessionExpiredClose = (reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSessionExpired(false);
    };
    const handleResetPasswordSuccessSnackbar = (reason) => {
        if (reason === "clickaway") {
            return;
        }
        setResetPasswordSuccess(false);
    };
    const handleAccountVerifiedClose = (reason) => {
        if (reason === "clickaway") {
            return;
        }
        setAccountVerifiedMsg("");
    };
    console.log(accountVerifiedMsg);
    return (
        <ThemeProvider theme={mdTheme}>
            <CssBaseline />

            <Box sx={{ width: "100%", height: "100vh", display: "flex" }}>
                <Box
                    sx={{
                        width: "60%",
                        height: "100vh",
                        backgroundColor: "whitesmoke",
                        padding: "22px",
                        overflow: "hidden",
                    }}
                >
                    <Box sx={{ display: "flex", textAlign: "center", alignItems: "center", marginTop: "10px" }}>
                        <Link exact to="/">
                            <img src={xaudit1} alt="/" style={{ width: "200px", height: "56px", marginTop: "-10px" }} />
                        </Link>
                    </Box>
                    <Box
                        sx={{
                            backgroundColor: "whitesmoke",
                            padding: "90px",
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            justifyItems: "center",
                            margin: "90px",
                            marginTop: "25px",
                        }}
                    >
                        <Typography
                            className="second-child-typography"
                            sx={{ textAlign: "center", fontSize: "30px", fontWeight: "500px", lineHeight: "38px" }}
                        >
                            We've been using Deliverability to kick start every new project and can't imagine working
                            without it.
                        </Typography>
                        <Avatar
                            sx={{
                                width: "70px",
                                height: "70px",
                                textAlign: "center",
                                margin: "15px",
                                marginLeft: "43%",
                            }}
                            alt="Remy Sharp"
                            src="/static/images/avatar/1.jpg"
                        />
                        <Typography
                            className="second-child-typography"
                            sx={{
                                textAlign: "center",
                                fontSize: "20px",
                                fontWeight: "600px",
                                lineHeight: "28px",
                                marginRight: "25px",
                            }}
                        >
                            Pippa Wilkinson
                        </Typography>
                        <Typography
                            className="second-child-typography"
                            sx={{
                                textAlign: "center",
                                fontSize: "16px",
                                fontWeight: "500px",
                                color: "GrayText",
                                marginRight: "30px",
                                lineHeight: "28px",
                            }}
                        >
                            Head Of Desgin, Layers
                        </Typography>
                    </Box>
                    <Typography
                        className="second-child-typography"
                        sx={{
                            fontSize: "18px",
                            fontWeight: "500px",
                            lineHeight: "20px",
                            color: "GrayText",
                            padding: "10px",
                        }}
                    >
                        XGrowth LLC
                    </Typography>
                </Box>
                <Box sx={{ width: "40%", height: "100vh" }}>
                    <div style={{ marginTop: "30%", marginLeft: "100px" }}>
                        <Typography
                            sx={{
                                fontSize: "30px",
                                fontWeight: 600,
                                lineHeight: "38px",
                                pb: 3,
                                color: "rgba(16, 24, 40, 1)",
                            }}
                        >
                            Welcome back
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "16px",
                                fontWeight: 400,
                                lineHeight: "28px",
                                pb: 3,
                                color: "rgba(102, 112, 133, 1)",
                                marginTop: "-20px",
                            }}
                        >
                            Welcome back! Please enter your details.
                        </Typography>
                        <Typography sx={{ fontSize: "14px", pb: 1 }}>Email</Typography>
                        <TextField
                            required
                            placeholder="Email"
                            type="email"
                            name="email"
                            value={email}
                            fullWidth
                            onChange={(e) => setEmail(e.target.value)}
                            sx={{ width: "370px", pb: 3 }}
                        />
                        <Typography sx={{ fontSize: "14px", pb: 1 }}>Password</Typography>
                        <TextField
                            required
                            placeholder="Password"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            sx={{ width: "370px", pb: 3 }}
                        />

                        <div>
                            {verification && (
                                <Typography sx={{ pt: 1 }}>
                                    <Link
                                        exact
                                        to="/verifyemail"
                                        style={{
                                            textDecoration: "none",
                                            color: "#3366CC",
                                            float: "left",
                                            paddingTop: "8px",
                                        }}
                                    >
                                        Click here to verify
                                    </Link>
                                </Typography>
                            )}
                        </div>
                        <Typography sx={{ pt: 1 }}>
                            <Link
                                exact
                                to="/forgotPassword"
                                style={{
                                    textDecoration: "none",
                                    color: "rgba(0, 74, 173, 1)",
                                    fontSize: "15px",
                                    fontWeight: 600,
                                    lineHeight: "21px",
                                }}
                            >
                                Forgot Password
                            </Link>
                        </Typography>
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            onClick={submitHandler}
                            sx={{
                                width: "370px",
                                mt: 1,
                                borderRadius: "6px",
                                textTransform: "none",
                                fontSize: "14px",
                                fontWeight: "700",
                                backgroundColor: "#481BEB",
                            }}
                            disabled={loginLoading}
                        >
                            {loginLoading && <CircularProgress size={25} />}
                            {!loginLoading && "Login"}
                        </Button>
                        <Typography sx={{ pt: 2 }}>
                            <Typography sx={{ mr: 0.5, float: "left" }}>Don't have an account?</Typography>
                            <Link exact to="/register" style={{ color: "rgba(0, 74, 173, 1)", textDecoration: "none" }}>
                                Sign Up Here
                            </Link>
                        </Typography>
                    </div>
                </Box>
            </Box>

            <Snackbar open={errorSnackbarOpen} autoHideDuration={6000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage === "" ? "Some error occured" : errorMessage}
                </Alert>
            </Snackbar>

            <Snackbar open={sessionExpired} autoHideDuration={6000} onClose={handleSessionExpiredClose}>
                <Alert onClose={handleSessionExpiredClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    Session expired, Login again!
                </Alert>
            </Snackbar>

            <Snackbar open={accountVerifiedMsg !== ""} autoHideDuration={6000} onClose={handleAccountVerifiedClose}>
                <Alert
                    onClose={handleAccountVerifiedClose}
                    variant="filled"
                    severity={accountVerifiedMsg === "account verified" ? "success" : "error"}
                    sx={{ width: "100%" }}
                >
                    {accountVerifiedMsg}
                </Alert>
            </Snackbar>
            <Snackbar open={resetPasswordSuccess} autoHideDuration={6000} onClose={handleResetPasswordSuccessSnackbar}>
                <Alert
                    onClose={handleResetPasswordSuccessSnackbar}
                    variant="filled"
                    severity="success"
                    sx={{ width: "100%" }}
                >
                    Password reset successfully
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
};

export default Login;
