import React from "react";
import "./Register.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
    CssBaseline,
    Box,
    FormControlLabel,
    Checkbox,
    Grid,
    Typography,
    Button,
    TextField,
    CircularProgress,
    Snackbar,
    Alert,
    InputAdornment,
    IconButton,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import xaudit1 from "../img/icons/xaudit1.png";
import Avatar from "@mui/material/Avatar";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const mdTheme = createTheme({
    typography: {
        fontFamily: ["League Spartan", "sans-serif"].join(","),
    },
});
const Register = () => {
    const navigate = useNavigate();
    const [firstname, setFirstname] = React.useState("");
    const [lastname, setLastname] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [buttonLoading, setButtonLoading] = React.useState(false);
    const [termsCondition, setTermsCondition] = React.useState(false);
    const [privacyPolicy, setPrivacyPolicy] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };
    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessSnackbarOpen(false);
    };

    function validatePW(str) {
        if (str.length <= 5) {
            return false;
        }

        if (!/[A-Z]/.test(str)) {
            return false;
        }
        if (!/[a-z]/.test(str)) {
            return false;
        }
        return true;
    }

    function validateEmail(data) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(data);
    }

    const registerButtonHandler = (e) => {
        e.preventDefault();
        setButtonLoading(true);

        if (firstname.length < 3 || !validateEmail(email)) {
            setErrorSnackbarOpen(true);
            setButtonLoading(false);
            setErrorMessage("Enter valid name and email");
            return;
        } else {
            if (validatePW(password)) {
                fetch(`${REACT_APP_SERVER_URL}/register`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    method: "POST",
                    credentials: "include",
                    body: new URLSearchParams({
                        fname: firstname,
                        lname: lastname,
                        password: password,
                        email: email,
                    }),
                })
                    .then((response) => {
                        if (response.status === 200) {
                            console.log("register successfully");
                            setButtonLoading(false);
                            setSuccessSnackbarOpen(true);
                            setTimeout(() => {
                                navigate("/login");
                            }, 5000);
                            return response.json();
                        } else {
                            console.log("register failed");
                            setButtonLoading(false);
                            setErrorSnackbarOpen(true);
                            return response.json();
                        }
                    })
                    .then((data) => {
                        console.log(data);
                        setErrorMessage(data.msg);
                        setSuccessMessage(data.msg);
                    });
            } else {
                setButtonLoading(false);
                setErrorSnackbarOpen(true);
                setErrorMessage("Enter Valid Password");
            }
        }
    };
    console.log(termsCondition);
    return (
        <ThemeProvider theme={mdTheme}>
            <CssBaseline />

            <Box className="full">
                <Box
                    sx={{
                        width: "60%",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        justifyItems: "center",
                        backgroundColor: "whitesmoke",
                    }}
                >
                    <Box sx={{ display: "flex", textAlign: "center", alignItems: "center", marginTop: "10px" }}>
                        <Link exact to="/">
                            <img src={xaudit1} alt="/" style={{ width: "200px", height: "56px", marginTop: "-10px" }} />
                        </Link>
                    </Box>
                    <Box sx={{ margin: "200px", marginTop: "150px" }}>
                        <Typography
                            className="second-child-typography"
                            sx={{ textAlign: "center", fontSize: "30px", fontWeight: "500px", lineHeight: "38px" }}
                        >
                            Deliverability has saved us thousands of hours of work. We’re able to spin up projects and
                            features much faster.
                        </Typography>
                        <Avatar
                            sx={{
                                width: "70px",
                                height: "70px",
                                textAlign: "center",
                                margin: "15px",
                                marginLeft: "43%",
                            }}
                            alt="Remy Sharp"
                            src="/static/images/avatar/1.jpg"
                        />
                        <Typography
                            className="second-child-typography"
                            sx={{
                                textAlign: "center",
                                fontSize: "20px",
                                fontWeight: "600px",
                                lineHeight: "28px",
                                marginRight: "25px",
                            }}
                        >
                            Pippa Wilkinson
                        </Typography>
                        <Typography
                            className="second-child-typography"
                            sx={{
                                textAlign: "center",
                                fontSize: "16px",
                                fontWeight: "500px",
                                color: "GrayText",
                                marginRight: "30px",
                                lineHeight: "28px",
                            }}
                        >
                            Head Of Desgin, Layers
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                fontSize: "18px",
                                fontWeight: "500px",
                                lineHeight: "20px",
                                color: "GrayText",
                                marginRight: "80%",
                                marginTop: "-10px",
                                padding: "10px",
                            }}
                        >
                            XGrowth LLC
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ width: "40%", backgroundColor: "white" }}>
                    <Box sx={{ padding: "40px", marginTop: "15px" }}>
                        <Typography sx={{ fontSize: "24px", fontWeight: 600, lineHeight: "38px", pb: 3 }}>
                            Create a New Account
                        </Typography>
                        <Grid container spacing={2} sx={{ pb: 2 }}>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Typography sx={{ fontSize: "14px", pb: 1 }}>First Name</Typography>
                                <TextField required onChange={(e) => setFirstname(e.target.value)} fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Typography sx={{ fontSize: "14px", pb: 1 }}>Last Name</Typography>
                                <TextField required onChange={(e) => setLastname(e.target.value)} fullWidth />
                            </Grid>
                        </Grid>
                        <Typography sx={{ fontSize: "14px", pb: 1 }}>Email</Typography>
                        <TextField
                            required
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            sx={{ pb: 2 }}
                        />
                        <Typography sx={{ fontSize: "14px", pb: 1 }}>Password</Typography>
                        <TextField
                            required
                            placeholder="Password"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                        />
                        <br></br>
                        <br></br>
                        <li>Password length must be Greater than 5</li>
                        <li>Must have one uppercase character</li>
                        <li>Must have one lowercase character</li>
                        <FormControlLabel
                            sx={{
                                marginTop: "10px",
                            }}
                            label={
                                <Typography>
                                    <Typography sx={{ mr: 0.5, float: "left" }}>I agree to</Typography>
                                    <Link exact to="/login" style={{ fontWeight: "700", color: "#000" }}>
                                        Terms and Conditions
                                    </Link>
                                </Typography>
                            }
                            control={
                                <Checkbox
                                    onChange={(e) => setTermsCondition(e.target.checked)}
                                    checked={termsCondition}
                                />
                            }
                        />
                        <br />
                        <FormControlLabel
                            sx={{
                                marginTop: "-15px",
                            }}
                            label={
                                <Typography>
                                    <Typography sx={{ mr: 0.5, float: "left" }}>I agree to</Typography>
                                    <Link exact to="/login" style={{ fontWeight: "700", color: "#000" }}>
                                        Privacy Policy
                                    </Link>
                                </Typography>
                            }
                            control={
                                <Checkbox
                                    onChange={(e) => setPrivacyPolicy(e.target.checked)}
                                    checked={privacyPolicy}
                                />
                            }
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            onClick={registerButtonHandler}
                            fullWidth
                            sx={{
                                mt: 1,
                                borderRadius: "6px",
                                textTransform: "none",
                                fontSize: "14px",
                                fontWeight: "700",
                                backgroundColor:
                                    buttonLoading || !termsCondition || !privacyPolicy ? "#e7e7e7" : "#481BEB",
                            }}
                            disabled={buttonLoading || !termsCondition || !privacyPolicy}
                        >
                            {buttonLoading && <CircularProgress size={25} />}
                            {!buttonLoading && "Register with Us"}
                        </Button>
                        <Typography sx={{ pt: 2 }}>
                            <Typography sx={{ mr: 0.5, float: "left" }}>Already have an account?</Typography>
                            <Link exact to="/login" style={{ color: "rgba(0, 74, 173, 1)", textDecoration: "none" }}>
                                Login
                            </Link>
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Snackbar open={errorSnackbarOpen} autoHideDuration={6000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={successSnackbarOpen} autoHideDuration={6000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
};

export default Register;
