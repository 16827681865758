import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { CheckBox } from "@mui/icons-material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import "./dnsrecords.css";
import {
    Box,
    Grid,
    Button,
    TableRow,
    TableCell,
    Typography,
    CircularProgress,
    Snackbar,
    Alert,
    CardContent,
    TableBody,
    TableContainer,
    TableHead,
    Table,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const options = ["A", "TXT", "MX", "NS", "SPF", "DMARC", "AAAA"];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#004aad",
        color: theme.palette.common.white,
        fontSize: 18,
        fontWeight: "bold",
        textAlign: "center",
        padding: "10px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        textAlign: "center",
        padding: "10px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
        backgroundColor: "#f1f1f1",
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const DnsRecords = () => {
    const [domain, setDomain] = useState([]);
    const [recordType, setRecordType] = useState("");
    const [Loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [result, setResult] = useState([]);
    const [mx, setMx] = useState([]);
    const [spf, setSpf] = useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(2);
    const [userClientNames, setUserClientNames] = useState([]);
    const [value, setValue] = React.useState(null);
    const [domainValue, setDomainValue] = React.useState(null);
    const [addNewDomain, setAddNewDomain] = React.useState(false);
    const [addDomainLoading, setAddDomainLoading] = useState(false);
    const filter = createFilterOptions();

    useEffect(() => {
        findClientNames();
    }, []);

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };

    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessSnackbarOpen(false);
    };

    const handleMenuItemClick = (event, index) => {
        setRecordType(options[index]);
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const isDomainPresent = (array, input) => {
        console.log(input, "getting input");
        if (input && input.domain) {
            return array.some((object) => object.domain === input.domain);
        }
        return false;
    };

    const handleSubmit = (e) => {
        // e.preventDefault();

        // console.log(e, 'e rpintitn')
        // console.log(e.target.value, 'value printnt')

        let recordType = e.target.value;
        setRecordType(recordType);
        //  setRecordType(e.target.value);

        if (domain === "") {
            setErrorSnackbarOpen(true);
            setErrorMessage("Domain cannot be empty");
            return;
        } else {
            try {
                setButtonLoading(true);
                // setLoading(true);

                fetch(`${REACT_APP_SERVER_URL}/api/dns`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    method: "POST",
                    credentials: "include",
                    body: new URLSearchParams({
                        domain: domainValue.domain,
                        recordType: recordType,
                    }),
                })
                    .then((response) => {
                        setButtonLoading(false);
                        setLoading(false);

                        return response.json();
                    })
                    .then((data) => {
                        if (data.status === 200) {
                            if (recordType === "MX") {
                                setMx(data.data);
                                setResult([]);
                                setSpf([]);
                            } else if (recordType === "SPF") {
                                setSpf(data.data);
                                setMx([]);
                                setResult([]);
                            } else {
                                setResult(data.data);
                                setMx([]);
                                setSpf([]);
                            }
                            return;
                        } else {
                            setErrorSnackbarOpen(true);
                            setErrorMessage(`No ${recordType} Record found`);
                            setResult([]);
                            setMx([]);
                            setSpf([]);

                            return;
                        }
                    });
            } catch (error) {
                console.log(error);
            }
        }
    };

    const findClientNames = () => {
        setLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/findClientNames`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                setLoading(false);
                return response.json();
            })
            .then((data) => {
                if (data.status === 200) {
                    setUserClientNames(data.data);
                } else {
                    console.log(data.message, "Error Fetching domains");
                }
            })
            .catch((err) => {
                console.log(err, "Error Fetching domains");
            });
    };

    async function handleDomainList(client) {
        console.log(client, "getting clientName");

        fetch(`${REACT_APP_SERVER_URL}/findingDomainListWithClientName?ClientName=${client}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                setLoading(false);
                return response.json();
            })
            .then((data) => {
                if (data.status === 200) {
                    setDomain(data.data);
                } else {
                    console.log(data.message, "Error Fetching domains");
                }
            })
            .catch((err) => {
                console.log(err, "Error Fetching domains");
            });
    }

    const addDomain = () => {
        // if (!client) {
        //     setErrorSnackbarOpen(true);
        //     setErrorMessage('Please enter the client Name which wants to associated with domains.');
        //     return;
        // }

        // if (!domain) {
        //     setErrorSnackbarOpen(true);
        //     setErrorMessage('Please enter the domain.');
        //     return;
        // }

        if (domainValue.domain && domainValue.domain.length < 4) {
            setErrorSnackbarOpen(true);
            setErrorMessage("Enter Valid domain.");
            return;
        }

        setAddDomainLoading(true);

        console.log(domain, "domain is getting");
        fetch(`${REACT_APP_SERVER_URL}/addDomain`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
            method: "POST",
            body: JSON.stringify({
                domain: domainValue.domain,
                client: value.clientName,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setAddDomainLoading(false);

                if (data.status === 200) {
                    // onNext(domain, data.txt)
                    setSuccessSnackbarOpen(true);
                    setSuccessMessage(`${data.domain}-Domain Added Successfully`);
                } else {
                    setErrorSnackbarOpen(true);
                    setErrorMessage("Invalid Domain");
                }
            });
    };
    const SkeletonTableBody = ({ rowCount = 5 }) => {
        const skeletonRows = [...Array(rowCount)].map((_, index) => (
            <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                    <Skeleton variant="text" width={200} />
                </StyledTableCell>
                <StyledTableCell align="right">
                    <Skeleton variant="text" width={200} />
                </StyledTableCell>
                <StyledTableCell align="right">
                    <Skeleton variant="text" width={200} />
                </StyledTableCell>
                <StyledTableCell align="right">
                    <Skeleton variant="text" width={200} />
                </StyledTableCell>
                <StyledTableCell align="right">
                    <Skeleton variant="text" width={200} />
                </StyledTableCell>
            </StyledTableRow>
        ));
        return <TableBody>{skeletonRows}</TableBody>;
    };

    return (
        <Box sx={{ marginTop: "-8px", overflow: "hidden" }}>
            <Box sx={{ textAlign: "center" }}>
                <span style={{ fontSize: "30px", fontWeight: "1200px", lineHeight: "38px" }}>DNS Record</span>
                <br />
                <span
                    style={{
                        textAlign: "center",
                        fontSize: "18px",
                        color: "rgba(71, 84, 103, 1)",
                        fontWeight: "400px",
                        lineHeight: "24px",
                        marginTop: "20px",
                    }}
                >
                    Discover all DNS records for a domain with ease. From A to TXT, view various record types
                    effortlessly. Enter the domain name, choose a record type from the dropdown.
                </span>
                <br />
                <span
                    style={{
                        textAlign: "center",
                        fontSize: "18px",
                        color: "rgba(71, 84, 103, 1)",
                        fontWeight: "400px",
                        lineHeight: "24px",
                        marginTop: "20px",
                    }}
                >
                    New updated record might take 24 to 48 hours to reflect.
                </span>
            </Box>
            <br />
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                {/* <TextField sx={{ padding: '5px' }} id="outlined-basic" label="Enter Client Name" type="text" variant="outlined" size='small' onChange={(e) => setClient(e.target.value)} /> */}
                {/* 
                    <TextField sx={{ padding: '5px' }} id="outlined-basic" label="Enter Domain Name" type="text" variant="outlined" size='small' onChange={(e) => setDomain(e.target.value)} /> */}

                <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                            setValue({
                                clientName: newValue,
                            });
                            handleDomainList(newValue);
                        } else if (newValue && newValue.clientName) {
                            console.log(newValue.clientName, "in object");
                            setValue({
                                clientName: newValue.clientName,
                            });
                            handleDomainList(newValue.clientName);
                        } else {
                            console.log("lies inside the else block");
                            setValue(newValue);
                            handleDomainList(newValue);
                        }
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        console.log(filtered, "filtered..........");

                        const { inputValue } = params;

                        const isExisting = options.some((option) => inputValue === option.clientName);
                        if (inputValue !== "" && !isExisting) {
                            filtered.push({
                                inputValue,
                                clientName: `${inputValue}`,
                            });
                        }

                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={userClientNames}
                    getOptionLabel={(option) => {
                        if (typeof option === "string") {
                            return option;
                        }
                        if (option.clientName) {
                            return option.clientName;
                        }
                        return option.clientName;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.clientName}</li>}
                    sx={{ width: 250, height: "30px" }}
                    freeSolo
                    renderInput={(params) => <TextField {...params} label="Client Name" />}
                />

                <Autocomplete
                    value={domainValue}
                    onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                            setDomainValue({
                                domain: newValue,
                            });
                        } else if (newValue && newValue.domain) {
                            console.log(newValue.domain, "in object");
                            setDomainValue({
                                domain: newValue.domain,
                            });
                        } else {
                            console.log("lies inside the else block");
                            setDomainValue(newValue);
                        }

                        console.log(isDomainPresent(domain, newValue), "printingdomingpresent");

                        if (isDomainPresent(domain, newValue)) {
                            setAddNewDomain(false);
                        } else {
                            console.log("lies in else block...");
                            setAddNewDomain(true);
                        }
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;

                        const isExisting = options.some((option) => inputValue === option.domain);
                        if (inputValue !== "" && !isExisting) {
                            filtered.push({
                                inputValue,
                                domain: `${inputValue}`,
                            });
                        }

                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={domain}
                    getOptionLabel={(option) => {
                        if (typeof option === "string") {
                            return option;
                        }
                        if (option.domain) {
                            return option.domain;
                        }
                        return option.domain;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.domain}</li>}
                    sx={{ width: 250, marginLeft: "15px" }}
                    freeSolo
                    renderInput={(params) => <TextField {...params} label="Choose Domain" />}
                />

                <form>
                    {domainValue !== null &&
                        value !== null &&
                        addNewDomain &&
                        (addDomainLoading ? (
                            <CircularProgress size={25} />
                        ) : (
                            <Button
                                onClick={addDomain}
                                sx={{ background: "#004aad", color: "white", ml: 3, padding: "10px", marginTop: "5px" }}
                                variant="contained"
                            >
                                Add this Domain
                            </Button>
                        ))}
                </form>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    textAlign: "center",
                    margin: "30px",
                    justifyContent: "center",
                    marginTop: "30px",
                }}
            >
                <Button
                    sx={{ padding: "8px", margin: "8px", background: "#004aad" }}
                    variant="contained"
                    value="A"
                    onClick={handleSubmit}
                >
                    A
                </Button>
                <Button
                    sx={{ padding: "10px", margin: "8px", background: "#004aad" }}
                    variant="contained"
                    value="TXT"
                    onClick={handleSubmit}
                >
                    TXT
                </Button>
                <Button
                    sx={{ padding: "10px", margin: "8px", background: "#004aad" }}
                    variant="contained"
                    value="MX"
                    onClick={handleSubmit}
                >
                    MX
                </Button>
                <Button
                    sx={{ padding: "10px", margin: "8px", background: "#004aad" }}
                    variant="contained"
                    value="NS"
                    onClick={handleSubmit}
                >
                    NS
                </Button>
                <Button
                    sx={{ padding: "10px", margin: "8px", background: "#004aad" }}
                    variant="contained"
                    value="SPF"
                    onClick={handleSubmit}
                >
                    SPF
                </Button>
                <Button
                    sx={{ padding: "10px", margin: "8px", background: "#004aad" }}
                    variant="contained"
                    value="DMARC"
                    onClick={handleSubmit}
                >
                    DMARC
                </Button>
                <Button
                    sx={{ padding: "10px", margin: "8px", background: "#004aad" }}
                    variant="contained"
                    value="AAAA"
                    onClick={handleSubmit}
                >
                    AAAA
                </Button>
            </Box>
            <Box sx={{ display: "flex", marginBottom: 2.5 }}>
                <Box sx={{ marginLeft: "30px" }}>
                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-bu disabled={index === 2}tton-menu" autoFocusItem>
                                            {options.map((option, index) => (
                                                <MenuItem
                                                    key={option}
                                                    selected={index === selectedIndex}
                                                    onClick={(event) => handleMenuItemClick(event, index)}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Box>
            </Box>

            {recordType && (
                <Typography variant="body1" sx={{ mb: 2 }}>
                    {recordType === "A" && (
                        <span style={{ fontSize: "20px" }}>
                            <span style={{ fontSize: "22px" }}>
                                <b>Record Lookup</b>
                            </span>
                            <br />
                            The A Record, or address record, links your domain or sub-domain to an IPv4 address.
                        </span>
                    )}

                    {recordType === "TXT" && (
                        <span style={{ fontSize: "20px" }}>
                            <span style={{ fontSize: "22px" }}>
                                <b>TXT Record Lookup</b>
                            </span>
                            <br />
                            The TXT Record lets administrators insert custom text into DNS records. Common usemucus with
                            bloods include SPF, DKIM, and DMARC for email authentication.
                        </span>
                    )}

                    {recordType === "MX" && (
                        <span style={{ fontSize: "20px" }}>
                            <span style={{ fontSize: "22px" }}>
                                <b>MX Record Lookup</b>
                            </span>
                            <br />
                            The MX Record directs email traffic by specifying mail exchange servers responsible for
                            routing emails to their intended destinations.
                        </span>
                    )}

                    {recordType === "NS" && (
                        <span style={{ fontSize: "20px" }}>
                            <span style={{ fontSize: "22px" }}>
                                <b>NS Record Lookup</b>
                            </span>
                            <br />
                            The NS Record points to authoritative name servers responsible for managing and publishing
                            DNS records for your domain.
                        </span>
                    )}

                    {recordType === "SPF" && (
                        <span style={{ fontSize: "20px" }}>
                            <span style={{ fontSize: "22px" }}>
                                <b>SPF Record Lookup</b>
                            </span>
                            <br />
                            Check the existence of SPF records in the 'TXT' DNS records of a domain or host to validate
                            email authenticity.
                        </span>
                    )}

                    {recordType === "DMARC" && (
                        <span style={{ fontSize: "20px" }}>
                            <span style={{ fontSize: "22px" }}>
                                <b>DMARC Record Lookup</b>
                            </span>
                            <br />
                            Ensure email security by verifying your domain's DMARC policy. Improve email deliverability
                            and protect against unauthorized use.
                        </span>
                    )}

                    {recordType === "AAAA" && (
                        <span style={{ fontSize: "20px" }}>
                            <span style={{ fontSize: "22px" }}>
                                <b>AAAA Record Lookup</b>
                            </span>
                            <br />
                            The AAAA Record, or address record, links your domain or sub-domain to an IPv6 address.
                        </span>
                    )}
                </Typography>
            )}
            {buttonLoading ? (
                <SkeletonTableBody />
            ) : (
                <Box sx={{ mt: 2 }}>
                    {result.length !== 0 && (
                        <Box>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>DessertRecord</StyledTableCell>
                                            <StyledTableCell align="right">Type</StyledTableCell>
                                            <StyledTableCell align="right">Domain</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {result &&
                                            result.map((data, i) => (
                                                <StyledTableRow key={i}>
                                                    <StyledTableCell>
                                                        {" "}
                                                        <Typography
                                                            sx={{
                                                                fontSize: "16px",
                                                                fontWeight: 500,
                                                                lineHeight: "18px",
                                                                overflowX: "auto",
                                                                overflowY: "auto",
                                                                whiteSpace: "pre-wrap",
                                                                wordBreak: "keep-all",
                                                            }}
                                                        >
                                                            {data.data}
                                                        </Typography>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {" "}
                                                        <Typography
                                                            sx={{
                                                                fontSize: "16px",
                                                                fontWeight: 500,
                                                                lineHeight: "18px",
                                                                marginLeft: "30px",
                                                                whiteSpace: "pre-wrap",
                                                            }}
                                                        >
                                                            {data.record}
                                                        </Typography>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {" "}
                                                        <Typography
                                                            sx={{
                                                                fontSize: "16px",
                                                                fontWeight: 500,
                                                                lineHeight: "18px",
                                                                marginLeft: "110px",
                                                            }}
                                                        >
                                                            {data.domain}
                                                        </Typography>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {/* <CardContent>
                                <Grid
                                    style={{
                                        width: "102.7%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        fontWeight: 600,
                                        color: "white",
                                        fontSize: "16px",
                                        background: "#004aad",
                                        borderRadius: "10px",
                                        padding: "1%",
                                        marginTop: "-10px",
                                        lineHeight: "18px",
                                    }}
                                    container
                                    spacing={2}
                                    alignItems="center"
                                >
                                    <Grid item sm={7} sx={{ marginTop: "-10px" }}>
                                        <label>Record</label>
                                    </Grid>
                                    <Grid item sm={1} sx={{ marginTop: "-10px" }}>
                                        <label>Type</label>
                                    </Grid>
                                    <Grid item sm={2} sx={{ marginTop: "-10px" }}>
                                        <label>Domain</label>
                                    </Grid>
                                </Grid>
                            </CardContent> */}

                            {/* {result &&
                                result.map((data, i) => (
                                    <div style={{ display: "flex", justifyContent: "space-between", gap: 4 }}>
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "auto",
                                                border: "0.1rem solid #EEE7DA",
                                                marginBottom: "1%",
                                                padding: "1.5%",
                                                borderRadius: "10px",
                                                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                                cursor: data.status === "Verified" ? "pointer" : "default",
                                                backgroundColor: data.status === "Verified" ? "#f5f5f5" : "transparent",
                                                marginTop: "-8px",
                                            }}
                                        >
                                            <CardContent>
                                                <Grid
                                                    sx={{ marginTop: "-5px", overflowX: "hidden" }}
                                                    container
                                                    spacing={2}
                                                    alignItems="center"
                                                    key={i}
                                                >
                                                    <Grid sm={8}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "16px",
                                                                fontWeight: 500,
                                                                lineHeight: "18px",
                                                                overflowX: "auto",
                                                                overflowY: "auto",
                                                                whiteSpace: "pre-wrap",
                                                                wordBreak: "keep-all",
                                                            }}
                                                        >
                                                            {data.data}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid sm={1}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "16px",
                                                                fontWeight: 500,
                                                                lineHeight: "18px",
                                                                marginLeft: "30px",
                                                                whiteSpace: "pre-wrap",
                                                            }}
                                                        >
                                                            {data.record}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid sm={2}>
                                                        {" "}
                                                        <Typography
                                                            sx={{
                                                                fontSize: "16px",
                                                                fontWeight: 500,
                                                                lineHeight: "18px",
                                                                marginLeft: "110px",
                                                            }}
                                                        >
                                                            {data.domain}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </div>
                                    </div>
                                ))} */}
                        </Box>
                    )}
                    {mx.length !== 0 && (
                        <Box>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell> Perf</StyledTableCell>
                                            <StyledTableCell align="right">Hostname</StyledTableCell>
                                            <StyledTableCell align="right">Type</StyledTableCell>
                                            <StyledTableCell align="right">Domain</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {mx &&
                                            mx.map((data, i) => (
                                                <StyledTableRow key={i}>
                                                    <StyledTableCell component="th" scope="row">
                                                        {data.data.priority}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {" "}
                                                        {data.data.exchange}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right"> {data.record}</StyledTableCell>
                                                    <StyledTableCell align="right"> {data.domain}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {/* <CardContent>
                                <Grid
                                    style={{
                                        width: "102.7%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        fontWeight: 600,
                                        color: "white",
                                        fontSize: "16px",
                                        background: "#004aad",
                                        borderRadius: "10px",
                                        padding: "1%",
                                        marginTop: "-10px",
                                        lineHeight: "18px",
                                    }}
                                    container
                                    spacing={2}
                                    alignItems="center"
                                >
                                    <Grid item sm={2} sx={{ marginTop: "-10px" }}>
                                        <label>Perf</label>
                                    </Grid>
                                    <Grid item sm={5} sx={{ marginTop: "-10px" }}>
                                        <label>Hostname</label>
                                    </Grid>
                                    <Grid item sm={2} sx={{ marginTop: "-10px" }}>
                                        <label>Type</label>
                                    </Grid>
                                    <Grid item sm={2} sx={{ marginTop: "-10px" }}>
                                        <label>Domain</label>
                                    </Grid>
                                </Grid>
                            </CardContent> */}
                            {/* {mx &&
                                mx.map((data, i) => (
                                    <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "auto",
                                                border: "0.1rem solid #EEE7DA",
                                                marginBottom: "1%",
                                                padding: "1.5%",
                                                borderRadius: "10px",
                                                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                                cursor: data.status === "Verified" ? "pointer" : "default",
                                                backgroundColor: data.status === "Verified" ? "#f5f5f5" : "transparent",
                                                marginTop: "-8px",
                                            }}
                                        >
                                            <CardContent>
                                                <Grid
                                                    sx={{ marginTop: "-5px" }}
                                                    container
                                                    spacing={5}
                                                    alignItems="center"
                                                    key={i}
                                                >
                                                    <Grid sx={{ marginLeft: "30px" }} sm={2}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "16px",
                                                                fontWeight: 500,
                                                                lineHeight: "18px",
                                                            }}
                                                        >
                                                            {data.data.priority}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid sm={4}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "16px",
                                                                fontWeight: 500,
                                                                lineHeight: "18px",
                                                            }}
                                                        >
                                                            {data.data.exchange}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid sm={2}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "16px",
                                                                fontWeight: 500,
                                                                lineHeight: "18px",
                                                                marginLeft: "160px",
                                                            }}
                                                        >
                                                            {data.record}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid sm={2}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "16px",
                                                                fontWeight: 500,
                                                                lineHeight: "18px",
                                                                marginLeft: "140px",
                                                            }}
                                                        >
                                                            {data.domain}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </div>
                                    </div>
                                ))} */}
                        </Box>
                    )}
                    {spf.length !== 0 && (
                        <Box>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell> Record</StyledTableCell>
                                            <StyledTableCell align="right">Verified</StyledTableCell>
                                            <StyledTableCell align="right">Type</StyledTableCell>
                                            <StyledTableCell align="right">Domain</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {spf &&
                                            spf.map((data, i) => (
                                                <StyledTableRow key={i}>
                                                    <StyledTableCell component="th" scope="row">
                                                        {data.data}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right">
                                                        {<CheckBox sx={{ color: "green" }}></CheckBox>}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="right"> {data.record}</StyledTableCell>
                                                    <StyledTableCell align="right"> {data.domain}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {/* <CardContent>
                                <Grid
                                    style={{
                                        width: "102.7%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        fontWeight: 600,
                                        color: "white",
                                        fontSize: "16px",
                                        background: "#004aad",
                                        borderRadius: "10px",
                                        padding: "1%",
                                        marginTop: "-10px",
                                        lineHeight: "18px",
                                    }}
                                    container
                                    spacing={2}
                                    alignItems="center"
                                >
                                    <Grid item sm={5} sx={{ marginTop: "-10px" }}>
                                        <label>Record</label>
                                    </Grid>
                                    <Grid item sm={1.5} sx={{ marginTop: "-10px" }}>
                                        <label>Verified</label>
                                    </Grid>
                                    <Grid item sm={1.5} sx={{ marginTop: "-10px" }}>
                                        <label>Type</label>
                                    </Grid>
                                    <Grid item sm={1.5} sx={{ marginTop: "-10px" }}>
                                        <label>Domain</label>
                                    </Grid>
                                </Grid>
                            </CardContent> */}
                            {/* 
                            {spf &&
                                spf.map((data, i) => (
                                    <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "auto",
                                                border: "0.1rem solid #EEE7DA",
                                                marginBottom: "1%",
                                                padding: "1.5%",
                                                borderRadius: "10px",
                                                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                                cursor: data.status === "Verified" ? "pointer" : "default",
                                                backgroundColor: data.status === "Verified" ? "#f5f5f5" : "transparent",
                                                marginTop: "-8px",
                                            }}
                                        >
                                            <CardContent>
                                                <Grid
                                                    sx={{ marginTop: "-5px" }}
                                                    container
                                                    spacing={5}
                                                    alignItems="center"
                                                    key={i}
                                                >
                                                    <Grid sx={{ marginLeft: "30px", marginTop: "-10px" }} sm={6}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "16px",
                                                                fontWeight: 500,
                                                                lineHeight: "18px",
                                                            }}
                                                        >
                                                            {data.data}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid sm={2}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "16px",
                                                                fontWeight: 500,
                                                                lineHeight: "18px",
                                                            }}
                                                        >
                                                            {<CheckBox sx={{ color: "green" }}></CheckBox>}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid sm={1}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "16px",
                                                                fontWeight: 500,
                                                                lineHeight: "18px",
                                                                marginLeft: "25px",
                                                            }}
                                                        >
                                                            {data.record}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid sm={1}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "16px",
                                                                fontWeight: 500,
                                                                lineHeight: "18px",
                                                                marginLeft: "110px",
                                                            }}
                                                        >
                                                            {data.domain}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </div>
                                    </div>
                                ))} */}
                        </Box>
                    )}
                </Box>
            )}

            <Snackbar open={errorSnackbarOpen} autoHideDuration={2000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={successSnackbarOpen} autoHideDuration={6000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default DnsRecords;
