import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
    Box,
    Paper,
    Button,
    Typography,
    CardContent,
    Dialog,
    DialogActions,
    DialogTitle,
    InputBase,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import {
    TableRow,
    TableCell,
    CircularProgress,
    Snackbar,
    Alert,
    TableContainer,
    TableHead,
    TableBody,
    Table,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Delete, Verified } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { hover } from "@testing-library/user-event/dist/hover";
import TextField from "@mui/material/TextField";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Autocomplete from "@mui/material/Autocomplete";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontSize: "1rem",
        fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "0.875rem",
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border

    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    })
);
const DomainName = () => {
    const { clientName } = useParams();
    const navigate = useNavigate();
    const [domain, setDomain] = useState([]);
    const [selectedDomain, setSelectedDomain] = useState("");
    const [txt, setTxt] = useState("");
    const [addDomain, setAddDomain] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteIpDialougeOpen, setdDeleteIpDialougeOpen] = React.useState(false);
    const [Loader, setLoader] = useState(false);
    const [deleteId, setdeleteId] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = React.useState(false);
    const [userInput, setUserInput] = useState("");
    const [userClientNames, setUserClientNames] = useState([]);
    const [filterInputData, setFilterInputData] = React.useState("All");
    const [selectedDate, setSelectedDate] = useState(null);
    const [stroreClient, setStoreClient] = useState(null);

    useEffect(() => {
        handleDomainList();
    }, []);

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };
    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSuccessSnackbarOpen(false);
    };

    const FetchDomains = (e) => {
        setLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/domainslist`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                setLoading(false);
                return response.json();
            })
            .then((data) => {
                if (data.status === 200) {
                    setDomain(data.data);
                } else {
                    console.log(data.message, "Error Fetching domains");
                }
            })
            .catch((err) => {
                console.log(err, "Error Fetching domains");
            });
    };

    const findClientNames = () => {
        setLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/findClientNames`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                setLoading(false);
                return response.json();
            })
            .then((data) => {
                if (data.status === 200) {
                    setUserClientNames(data.data);
                } else {
                    console.log(data.message, "Error Fetching domains");
                }
            })
            .catch((err) => {
                console.log(err, "Error Fetching domains");
            });
    };

    function handleDomainClick(domain) {
        if (domain.status === "Verified") {
            window.location.replace(`${process.env.REACT_APP_URL}/dashboard/features/${domain.domain}/${clientName}`);
        } else {
            console.log("%cNot verified", "color: red"); // Change font color to red
        }
    }

    function formatDate(inputDate) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

        const parts = inputDate.split("-");
        if (parts.length === 3) {
            const day = parseInt(parts[0], 10);
            const monthIndex = parseInt(parts[1], 10) - 1;
            const year = parseInt(parts[2], 10);

            if (!isNaN(day) && !isNaN(monthIndex) && !isNaN(year)) {
                if (monthIndex >= 0 && monthIndex < months.length) {
                    const monthName = months[monthIndex];
                    return `${day} ${monthName} ${year}`;
                }
            }
        }

        return "Invalid Date";
    }

    function DeleteIpHandle(e) {
        let domain = e;
        setLoader(true);
        fetch(`${REACT_APP_SERVER_URL}/DeleteDomainRep`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "POST",
            credentials: "include",
            body: new URLSearchParams({
                domain: domain,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setLoader(false);
                if (data.status === 200) {
                    setSuccessSnackbarOpen(true);
                    setSuccessMessage("Domain Deleted Successfully");
                    // FetchDomains();
                    handleDomainList(stroreClient);
                } else {
                    setErrorSnackbarOpen(true);
                    setErrorMessage("Internal server error");
                }
            });
    }

    function oneClickVerify(d) {
        let checkDomain = d;
        // console.log('hello', checkDomain)

        setLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/checkVerificationTxt`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            credentials: "include",
            method: "POST",
            body: JSON.stringify({
                domain: checkDomain,
            }),
        })
            .then((response) => {
                setLoading(false);
                return response.json();
            })
            .then((data) => {
                setLoading(false);

                if (data.status === 200) {
                    // onNext(domain)
                    setSuccessSnackbarOpen(true);
                    setSuccessMessage("Verified successfully");
                    FetchDomains();
                } else {
                    setErrorSnackbarOpen(true);
                    setErrorMessage("Verification Failed, Kindly add this domain to the postmaster first");
                    FetchDomains();
                    // alert('Verification Failed, Kindly add this domain to the postmaster')
                }
            });
    }

    function inputHanlder(e) {
        let input = e.target.value.toLowerCase();
        setUserInput(input);
    }

    const filterData = domain.filter((d) => {
        const isDomainMatch = d.domain.toLowerCase().includes(userInput.toLowerCase());
        if (filterInputData === "All") {
            return isDomainMatch;
        }
        if (filterInputData === "Verified") {
            return d.status === "Verified" && isDomainMatch;
        }
        if (filterInputData === "Unverified") {
            return d.status !== "Verified" && isDomainMatch;
        }
        if (filterInputData === "Date" && selectedDate) {
            const domainDate = dayjs(d.create_time, "D-M-YYYY");
            return domainDate.isValid() && domainDate.isSame(selectedDate, "day") && isDomainMatch;
        }
        return false;
    });

    const handleChange = (e) => {
        setFilterInputData(e.target.value);
    };

    const handleDateChange = (newValue) => {
        setSelectedDate(newValue);
        setFilterInputData("Date");
    };

    const clientNamefilterData = userClientNames.filter((d) => {
        console.log(d, "d is defined");

        if (userInput === "") {
            return d;
        } else {
            return d.clientName.toLowerCase().includes(userInput);
        }
    });

    async function handleDomainList(client) {
        console.log(client, "getting clientName");
        setLoading(true);
        fetch(`${REACT_APP_SERVER_URL}/findingDomainListWithClientName?ClientName=${clientName}`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "GET",
            credentials: "include",
        })
            .then((response) => {
                setLoading(false);
                return response.json();
            })
            .then((data) => {
                console.log(data, "getting data");
                if (data.status === 200) {
                    setDomain(data.data);
                } else {
                    console.log(data.message, "Error Fetching domains");
                }
            })
            .catch((err) => {
                console.log(err, "Error Fetching domains");
            });
    }

    let sendingValues = [
        {
            label: "Date",
        },
        {
            label: "Verified",
        },
        {
            label: "Not verified",
        },
    ];

    async function filterFunctionGetting(e, newValue) {
        console.log("working here button");
        console.log(e, newValue, "value");
    }
    const handleClick = () => {
        navigate("/dashboard/DomainReputation");
    };

    return (
        <div className="container">
            {Loader ? (
                <Box sx={{ width: "100%", display: "center", justifyContent: "center" }}>
                    <CircularProgress />
                    <Typography>Deleting...</Typography>
                </Box>
            ) : (
                <div>
                    <Dialog
                        PaperProps={{
                            style: { borderRadius: "20px" },
                        }}
                        open={deleteIpDialougeOpen}
                    >
                        <div
                            style={{
                                width: "500px",
                                height: "200px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <DialogTitle id="alert-dialog-title">
                                <p style={{ color: "red", fontWeight: "bold", fontSize: "20px" }}>
                                    Are you sure you want to delete account?
                                </p>
                            </DialogTitle>
                            <DialogActions sx={{ mb: 4, float: "right" }}>
                                <Button
                                    onClick={() => {
                                        DeleteIpHandle(deleteId);
                                        setdDeleteIpDialougeOpen(false);
                                    }}
                                    variant="contained"
                                    sx={{ color: "#fff" }}
                                    color="success"
                                >
                                    Delete
                                </Button>
                                <Button
                                    onClick={() => {
                                        setdDeleteIpDialougeOpen(false);
                                        setdeleteId("");
                                    }}
                                    variant="contained"
                                    sx={{ color: "#fff" }}
                                    color="error"
                                    disabled={false}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </div>
                    </Dialog>

                    <Box>
                        <>
                            <Box>
                                <Button sx={{ marginTop: "15px" }} onClick={handleClick}>
                                    <ArrowBackIosIcon />
                                    Back
                                </Button>
                                <Box sx={{ display: "flex", flexDirection: "row" }}>
                                    <Paper
                                        component="form"
                                        sx={{
                                            p: 2,
                                            mr: 2,
                                            display: "flex",
                                            alignItems: "center",
                                            width: "50%",
                                            height: "55px",
                                            boxShadow: "none",
                                            border: "1px solid rgba(71, 84, 103, 1)",
                                            float: "left",
                                            marginBottom: "10px",
                                            marginTop: "10px",
                                            fontsize: "16px",
                                            fontWeight: "700",
                                            boxshadow: "0px 2px 1px #1018280d,",
                                        }}
                                    >
                                        <SearchIcon sx={{ color: "#aaa" }} />
                                        <InputBase
                                            sx={{ flex: 1, border: "none" }}
                                            placeholder="Search Domains"
                                            onChange={inputHanlder}
                                            inputProps={{ "aria-label": "search for projects" }}
                                        />
                                    </Paper>
                                    <FormControl sx={{ width: "50%", height: "50px", marginTop: "9px" }}>
                                        <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={filterInputData}
                                            label="Filter"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={"All"}>All</MenuItem>
                                            <MenuItem value={"Verified"}>Verified</MenuItem>
                                            <MenuItem value={"Unverified"}>Unverified</MenuItem>
                                            <MenuItem value={"Date"}>Date</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {filterInputData === "Date" && (
                                        <Box mt={2}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    value={selectedDate}
                                                    onChange={handleDateChange}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Select Date" fullWidth />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                    )}
                                </Box>
                                {/* <CardContent sx={{ marginTop: "-20px" }}>
                                    <Grid
                                        style={{
                                            width: "102.7%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            fontWeight: 600,
                                            color: "white",
                                            fontSize: "16px",
                                            background: "#004aad",
                                            borderRadius: "10px",
                                            padding: "1%",
                                            marginTop: "30px",
                                            lineHeight: "18px",
                                        }}
                                        container
                                        spacing={2}
                                        alignItems="center"
                                    >
                                        <Grid item sm={4} sx={{ marginTop: "-7px" }}>
                                            <label>Domain</label>
                                        </Grid>
                                        <Grid item sm={2} sx={{ marginTop: "-7px" }}>
                                            <label>Status</label>
                                        </Grid>
                                        <Grid item sm={2} sx={{ marginTop: "-7px" }}>
                                            <label>Added</label>
                                        </Grid>
                                        <Grid item sm={2} sx={{ marginTop: "-7px" }}>
                                            <label>DELETE</label>
                                        </Grid>
                                    </Grid>
                                </CardContent> */}
                                {/* 
                                {filterData.map((data) => (
                                    <div
                                        key={data.domain}
                                        style={{ display: "flex", justifyContent: "space-between", gap: 4 }}
                                    >
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "60px",
                                                border: "0.1rem solid #EEE7DA",
                                                marginBottom: "1%",
                                                padding: "1.5%",
                                                borderRadius: "10px",
                                                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                                cursor: data.status === "Verified" ? "pointer" : "default",
                                                backgroundColor: data.status === "Verified" ? "#f5f5f5" : "transparent",
                                            }}
                                        >
                                            <CardContent>
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    alignItems="center"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        marginTop: "-20px",
                                                    }}
                                                >
                                                    <Grid sm={4} id="ip" onClick={() => handleDomainClick(data)}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "16px",
                                                                fontWeight: 500,
                                                                lineHeight: "18px",
                                                            }}
                                                        >
                                                            {data.domain}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid sm={2.5} id="ip">
                                                        {`${data.status}` === "Verified" ? (
                                                            <LightTooltip
                                                                title={
                                                                    <span style={{ color: "blue" }}>
                                                                        Click here to open
                                                                    </span>
                                                                }
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        width: "50%",
                                                                        textAlign: "center",
                                                                        color: "#004aad",
                                                                        border: "2px solid #004aad",
                                                                        fontSize: "15px",
                                                                        fontWeight: "600",
                                                                        lineHeight: "16.38px",
                                                                        padding: "8px",
                                                                        borderRadius: "5px",
                                                                    }}
                                                                    onClick={() => handleDomainClick(data)}
                                                                >
                                                                    {" "}
                                                                    Verified
                                                                </Box>
                                                            </LightTooltip>
                                                        ) : (
                                                            <LightTooltip
                                                                title={
                                                                    <span
                                                                        style={{ color: "rgba(255, 115, 115, 0.93)" }}
                                                                    >
                                                                        Click to Verified
                                                                    </span>
                                                                }
                                                            >
                                                                <Box
                                                                    onClick={() => oneClickVerify(data.domain)}
                                                                    className="verifyButton"
                                                                    sx={{
                                                                        width: "50%",
                                                                        textAlign: "center",
                                                                        color: "rgba(255, 115, 115, 0.93)",
                                                                        border: "2px solid rgba(255, 115, 115, 0.93)",
                                                                        fontSize: "15px",
                                                                        fontWeight: "600",
                                                                        lineHeight: "16.38px",
                                                                        padding: "8px",
                                                                        borderRadius: "5px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    Not verified
                                                                </Box>
                                                            </LightTooltip>
                                                        )}
                                                    </Grid>

                                                    <Grid sm={2.3} id="ip" onClick={() => handleDomainClick(data)}>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "16px",
                                                                fontWeight: 500,
                                                                lineHeight: "18px",
                                                            }}
                                                        >
                                                            {formatDate(data.create_time)}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid sm={1.7}>
                                                        {" "}
                                                        <Button
                                                            sx={{ color: "#EF6D6D" }}
                                                            onClick={() => {
                                                                setdDeleteIpDialougeOpen(true);
                                                                setdeleteId(data.domain);
                                                            }}
                                                        >
                                                            <LightTooltip
                                                                title={
                                                                    <span style={{ color: "red" }}>Delete Button</span>
                                                                }
                                                            >
                                                                <Delete />
                                                            </LightTooltip>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </div>
                                    </div>
                                ))} */}
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Client Name</StyledTableCell>
                                                <StyledTableCell>Status</StyledTableCell>
                                                <StyledTableCell>Added</StyledTableCell>
                                                <StyledTableCell>DELETE</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        {loading ? (
                                            <TableBody>
                                                {[...Array(5)].map((_, index) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell component="th" scope="row">
                                                            <Skeleton variant="text" />
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right">
                                                            <Skeleton variant="text" />
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right">
                                                            <Skeleton variant="rectangular" width={30} height={30} />
                                                        </StyledTableCell>
                                                        <StyledTableCell align="right">
                                                            <Skeleton variant="text" />
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                {filterData.map((data) => (
                                                    <TableRow key={data.domain}>
                                                        <StyledTableCell
                                                            component="th"
                                                            scope="row"
                                                            onClick={() => handleDomainClick(data)}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: "16px",
                                                                    fontWeight: 500,
                                                                    lineHeight: "18px",
                                                                }}
                                                            >
                                                                {data.domain}
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            {data.status === "Verified" ? (
                                                                <Box
                                                                    sx={{
                                                                        width: "50%",
                                                                        textAlign: "center",
                                                                        color: "#004aad",
                                                                        border: "2px solid #004aad",
                                                                        fontSize: "15px",
                                                                        fontWeight: "600",
                                                                        lineHeight: "16.38px",
                                                                        padding: "8px",
                                                                        borderRadius: "5px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => handleDomainClick(data)}
                                                                >
                                                                    Verified
                                                                </Box>
                                                            ) : (
                                                                <Box
                                                                    sx={{
                                                                        width: "50%",
                                                                        textAlign: "center",
                                                                        color: "rgba(255, 115, 115, 0.93)",
                                                                        border: "2px solid rgba(255, 115, 115, 0.93)",
                                                                        fontSize: "15px",
                                                                        fontWeight: "600",
                                                                        lineHeight: "16.38px",
                                                                        padding: "8px",
                                                                        borderRadius: "5px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => oneClickVerify(data.domain)}
                                                                >
                                                                    Not verified
                                                                </Box>
                                                            )}
                                                        </StyledTableCell>
                                                        <StyledTableCell onClick={() => handleDomainClick(data)}>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: "16px",
                                                                    fontWeight: 500,
                                                                    lineHeight: "18px",
                                                                }}
                                                            >
                                                                {formatDate(data.create_time)}
                                                            </Typography>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <Button
                                                                sx={{ color: "#EF6D6D" }}
                                                                onClick={() => {
                                                                    setdDeleteIpDialougeOpen(true);
                                                                    setdeleteId(data.domain);
                                                                }}
                                                            >
                                                                <Delete />
                                                            </Button>
                                                        </StyledTableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>
                            </Box>
                        </>
                    </Box>
                </div>
            )}
            <Snackbar open={errorSnackbarOpen} autoHideDuration={6000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={successSnackbarOpen} autoHideDuration={6000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default DomainName;
