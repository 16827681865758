import React, { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useTheme } from "@mui/material/styles";
import {
    Box,
    Button,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Container,
    List,
    ListItem,
    ListItemText,
    Paper,
    ClickAwayListener,
    Card,
    CardContent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { styled } from "@mui/system";
import { Doughnut } from "react-chartjs-2";
import { PieChart } from "@mui/x-charts/PieChart";
import { LineChart } from "@mui/x-charts/LineChart";
import { Pie, Line } from "react-chartjs-2";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

// const templates = Array.from({ length: 15 }, (_, i) => ({
//     id: i + 1,
//     name: `Template ${i + 1}`,
//     content: `This is the content of Template ${i + 1}.`,
// }));

const Scrollable = ({ children }) => (
    <div style={{ maxHeight: "calc(100vh - 360px)", overflowY: "auto" }}>{children}</div>
);

const templates = [
    { id: 1, name: "Template 1", title: "Title 1", subject: "Subject 1", content: "This is template 1's body" },
    { id: 2, name: "Template 2", title: "Title 2", subject: "Subject 2", content: "This is template 2's body" },
    { id: 3, name: "Template 3", title: "Title 2", subject: "Subject 2", content: "This is template 3's body" },
    { id: 4, name: "Template 4", title: "Title 2", subject: "Subject 2", content: "This is template 4's body" },
    { id: 5, name: "Template 5", title: "Title 2", subject: "Subject 2", content: "This is template 5's body" },
    { id: 6, name: "Template 6", title: "Title 2", subject: "Subject 2", content: "Body 2" },
    { id: 7, name: "Template 7", title: "Title 2", subject: "Subject 2", content: "Body 2" },
    { id: 8, name: "Template 8", title: "Title 2", subject: "Subject 2", content: "Body 2" },
    { id: 9, name: "Template 9", title: "Title 2", subject: "Subject 2", content: "Body 2" },
    { id: 10, name: "Template 10", title: "Title 2", subject: "Subject 2", content: "Body 2" },
    { id: 11, name: "Template 11", title: "Title 2", subject: "Subject 2", content: "Body 2" },
    { id: 12, name: "Template 12", title: "Title 2", subject: "Subject 2", content: "Body 2" },
    { id: 13, name: "Template 13", title: "Title 2", subject: "Subject 2", content: "Body 2" },
    // Add more templates here
];

const AuditReport = () => {
    const theme = useTheme();
    const [progress, setProgress] = useState(0);
    const [selectedTemplate, setSelectedTemplate] = useState(templates[0]);

    const [domainBlacklist, setDomainBlacklist] = useState([
        { name: "blacklist.domain1.com" },
        { name: "blacklist.domain2.com" },
        { name: "blacklist.domain3.com" },
        { name: "blacklist.domain4.com" },
        { name: "blacklist.domain5.com" },
    ]);

    const [ipBlacklist, setIpBlacklist] = useState([
        { name: "blacklist.domain1.com" },
        { name: "blacklist.domain2.com" },
        { name: "blacklist.domain3.com" },
        { name: "blacklist.domain4.com" },
        { name: "blacklist.domain5.com" },
    ]);

    const [isAccordionOpen, setIsAccordionOpen] = useState(false); // State to track accordion open/close

    useEffect(() => {
        // Simulated fetch of blacklist data from API
        const fetchBlacklistData = async () => {
            try {
                // Simulate API delay
                await new Promise((resolve) => setTimeout(resolve, 1000));
                // Set domain blacklist
                setDomainBlacklist([
                    { name: "blacklist.domain1.com" },
                    { name: "blacklist.domain2.com" },
                    { name: "blacklist.domain3.com" },
                    { name: "blacklist.domain4.com" },
                    { name: "blacklist.domain5.com" },
                ]);
                // Set IP blacklist
                setIpBlacklist([
                    { name: "blacklist.domain1.com" },
                    { name: "blacklist.domain2.com" },
                    { name: "blacklist.domain3.com" },
                    { name: "blacklist.domain4.com" },
                    { name: "blacklist.domain5.com" },
                ]);
            } catch (error) {
                console.error("Error fetching blacklist data:", error);
            }
        };

        fetchBlacklistData();
    }, []);

    const increaseProgress = () => {
        setProgress((prev) => (prev < 100 ? prev + 10 : 0));
    };

    const handleTemplateSelect = (template) => {
        setSelectedTemplate(template);
    };

    const tests = [
        { name: "SPF", status: "Failing" },
        { name: "DKIM", status: "Passing" },
        { name: "DMARC", status: "Failing" },
    ];

    // Determine DNS status based on the statuses of SPF, DKIM, and DMARC
    const dnsStatus = tests.every((test) => test.status === "Passing") ? "Passing" : "Failing";

    const domainTests = [
        { name: "Domain Blacklist", status: "Passing" },
        { name: "Domain Health", status: "Failing" },
    ];

    // Determine Domain Reputation status
    const domainStatus = domainTests.every((test) => test.status === "Passing") ? "Passing" : "Failing";

    const ipTests = [{ name: "IP Blacklist", status: "Passing" }];

    // Determine IP Reputation status
    const ipStatus = ipTests.every((test) => test.status === "Passing") ? "Passing" : "Failing";

    const toggleAccordion = () => {
        setIsAccordionOpen((prev) => !prev);
    };

    const closeAccordion = () => {
        setIsAccordionOpen(false);
    };

    const handleDelist = (type, name) => {
        // Assuming `name` is the IP address or domain name
        // Redirect to the IP address or domain
        window.location.href = `http://${name}`; // Assuming it's an HTTP URL
        // Alternatively, you can use `https://` for HTTPS URLs
    };

    const pieChartData = {
        labels: ["Passing", "Failing"],
        datasets: [
            {
                data: [2, 1], // Replace with actual data counts
                backgroundColor: ["green", "red"],
                hoverBackgroundColor: ["#4CAF50", "#F44336"],
            },
        ],
    };

    const data = {
        labels: ["Soft Bounce", "Hard Bounce"],
        datasets: [
            {
                data: [10, 15],
                backgroundColor: ["#ef9b20", "#9b19f5"],
                hoverBackgroundColor: ["white", "white"],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true, // Hides the default legend
            },
        },
    };

    const data2 = {
        labels: ["January", "February", "March", "April", "May", "June", "July"],
        datasets: [
            {
                label: "IP",
                data: [65, 59, 80, 81, 56, 55, 40],
                fill: false,
                borderColor: "rgba(75,192,192,1)",
                backgroundColor: "rgba(75,192,192,0.4)",
            },
            {
                label: "Domain",
                data: [28, 48, 40, 19, 86, 27, 90],
                fill: false,
                borderColor: "rgba(153,102,255,1)",
                backgroundColor: "rgba(153,102,255,0.4)",
            },
        ],
    };

    const options2 = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
                // text: "",
            },
        },
    };

    return (
        <>
            <Breadcrumbs
                aria-label="breadcrumb"
                separator="››"
                sx={{
                    margin: "16px 0",
                    padding: "8px",
                    borderRadius: "4px",
                    backgroundColor: "background.paper",
                    boxShadow: 1,
                }}
            >
                <Link
                    underline="hover"
                    color="inherit"
                    href="/dashboard/spreadsheet"
                    sx={{
                        color: "primary.main",
                        textDecoration: "none",
                        "&:hover": {
                            textDecoration: "underline",
                        },
                    }}
                >
                    BCC Insights
                </Link>
                <Link
                    underline="hover"
                    color="inherit"
                    href="/material-ui/getting-started/installation/"
                    sx={{
                        color: "primary.main",
                        textDecoration: "none",
                        "&:hover": {
                            textDecoration: "underline",
                        },
                    }}
                >
                    In Progress Page
                </Link>
                <Typography
                    color="text.primary"
                    sx={{
                        color: "text.primary",
                    }}
                >
                    Audit Report
                </Typography>
            </Breadcrumbs>

            <Container sx={{ marginTop: "30px", marginBottom: "30px" }}>
                <Grid container spacing={5}>
                    {/* Score Card Section */}
                    <Grid item xs={12} md={5}>
                        <Paper
                            sx={{
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: 2,
                                padding: "20px",
                                border: "1px solid #ddd",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)",
                                borderRadius: "15px",
                                backgroundColor: "#fff",
                                transform: "translateZ(0)",
                                transition: "transform 0.3s ease-in-out",
                                "&:hover": {
                                    transform: "scale(1.01)",
                                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.19)",
                                },
                            }}
                        >
                            <Typography variant="h4" gutterBottom>
                                Overall Audit Score
                            </Typography>
                            <Box
                                sx={{
                                    width: "180px",
                                    height: "180px",
                                    position: "relative",
                                }}
                            >
                                <CircularProgressbar
                                    value={progress}
                                    styles={buildStyles({
                                        pathColor: `rgba(54, 162, 235, ${progress / 100})`,
                                        trailColor: "#d6d6d6",
                                        backgroundColor: "#3e98c7",
                                        textSize: "16px", // Adjust the text size
                                    })}
                                />
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        fontSize: "20px",
                                        color: "green",
                                        textAlign: "center",
                                    }}
                                >
                                    {`${progress}%`}
                                </Box>
                            </Box>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={increaseProgress}
                                sx={{
                                    marginTop: 2,
                                }}
                            >
                                Increase Progress
                            </Button>
                        </Paper>
                    </Grid>

                    {/* Test Section */}
                    <Grid item xs={12} md={7}>
                        <ClickAwayListener onClickAway={closeAccordion}>
                            <Paper
                                sx={{
                                    maxHeight: "55vh", // Set a maximum height
                                    padding: "20px",
                                    border: "1px solid #ddd",
                                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                                    borderRadius: "8px",
                                    backgroundColor: "#fff",
                                    overflowY: "auto", // Add scroll bar on the Paper
                                    "&:hover": {
                                        transform: "scale(1.01)",
                                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.19)",
                                    },
                                }}
                            >
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={6}>
                                        <Typography variant="h6">Test</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="h6">Severity</Typography>
                                    </Grid>
                                </Grid>
                                <Accordion expanded={isAccordionOpen} onChange={toggleAccordion}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        sx={{ backgroundColor: "#f0f0f0" }}
                                    >
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={6}>
                                                <Typography>DNS</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {dnsStatus === "Passing" ? (
                                                    <Typography color="green">
                                                        <CheckCircleIcon /> Passing
                                                    </Typography>
                                                ) : (
                                                    <Typography color="red">
                                                        <ErrorIcon /> Failing
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ flexDirection: "column" }}>
                                        {tests.map((test, index) => (
                                            <Accordion key={index}>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={6}>
                                                            <Typography>{test.name}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {test.status === "Passing" ? (
                                                                <Typography color="green">
                                                                    <CheckCircleIcon /> Passing
                                                                </Typography>
                                                            ) : (
                                                                <Typography color="red">
                                                                    <ErrorIcon /> Failing
                                                                </Typography>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>Details about {test.name} test...</Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}
                                    </AccordionDetails>
                                </Accordion>

                                {/* Domain Reputation Accordion */}
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        sx={{ backgroundColor: "#f0f0f0" }}
                                    >
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={6}>
                                                <Typography>Domain Reputation</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {domainStatus === "Passing" ? (
                                                    <Typography color="green">
                                                        <CheckCircleIcon /> Passing
                                                    </Typography>
                                                ) : (
                                                    <Typography color="red">
                                                        <ErrorIcon /> Failing
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ flexDirection: "column" }}>
                                        {domainTests.map((test, index) => (
                                            <Accordion key={index}>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={6}>
                                                            <Typography>{test.name}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {test.status === "Passing" ? (
                                                                <Typography color="green">
                                                                    <CheckCircleIcon /> Passing
                                                                </Typography>
                                                            ) : (
                                                                <Typography color="red">
                                                                    <ErrorIcon /> Failing
                                                                </Typography>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>Details about {test.name}...</Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}
                                    </AccordionDetails>
                                </Accordion>

                                {/* IP Reputation Accordion */}
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        sx={{ backgroundColor: "#f0f0f0" }}
                                    >
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={6}>
                                                <Typography>IP Reputation</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {ipStatus === "Passing" ? (
                                                    <Typography color="green">
                                                        <CheckCircleIcon /> Passing
                                                    </Typography>
                                                ) : (
                                                    <Typography color="red">
                                                        <ErrorIcon /> Failing
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ flexDirection: "column" }}>
                                        {ipTests.map((test, index) => (
                                            <Accordion key={index}>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={6}>
                                                            <Typography>{test.name}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {test.status === "Passing" ? (
                                                                <Typography color="green">
                                                                    <CheckCircleIcon /> Passing
                                                                </Typography>
                                                            ) : (
                                                                <Typography color="red">
                                                                    <ErrorIcon /> Failing
                                                                </Typography>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>Details about {test.name}...</Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            </Paper>
                        </ClickAwayListener>
                    </Grid>

                    {/* <Grid container spacing={2}> */}
                    {/* Template List */}
                    <Grid item xs={12} md={4}>
                        {" "}
                        {/* Reduced width by changing md from 4 to 3 */}
                        <Paper
                            sx={{
                                padding: "20px",
                                border: "1px solid #ddd",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)",
                                borderRadius: "15px",
                                backgroundColor: "#fff",
                                height: "600px",
                                overflowY: templates.length > 10 ? "auto" : "hidden", // Scrollable if more than 10 items
                                transform: "translateZ(0)",
                                transition: "transform 0.3s ease-in-out",
                                "&:hover": {
                                    transform: "scale(1.01)", // Reduced hover effect
                                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2), 0 9px 30px rgba(0, 0, 0, 0.19)",
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    color: "#24a0ed",
                                    marginBottom: "20px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                                    fontWeight: "bold",
                                }}
                            >
                                TEMPLATES
                            </Typography>
                            <List>
                                {templates.map((template) => (
                                    <ListItem
                                        key={template.id}
                                        button
                                        selected={selectedTemplate.id === template.id}
                                        onClick={() => handleTemplateSelect(template)}
                                        sx={{
                                            border: "1px solid #ddd",
                                            borderRadius: "5px",
                                            backgroundColor: "white", // Set the background color for all items
                                            "&:hover": {
                                                backgroundColor: "#e0f2f1", // Hover effect
                                            },
                                            marginBottom: "2px",
                                            textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <ListItemText primary={template.name} sx={{ textAlign: "center" }} />
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    </Grid>

                    {/* Template Content */}
                    <Grid item xs={12} md={8}>
                        <Paper
                            sx={{
                                padding: "20px",
                                border: "1px solid #ddd",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)",
                                borderRadius: "15px",
                                backgroundColor: "#fff",
                                height: "600px",
                                overflowY: "auto", // Scrollable content
                                transform: "translateZ(0)",
                                transition: "transform 0.3s ease-in-out",
                                "&:hover": {
                                    transform: "scale(1.01)", // Reduced hover effect
                                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.2), 0 9px 30px rgba(0, 0, 0, 0.19)",
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    marginBottom: "20px",
                                    color: "#00796b",
                                    color: "#24a0ed",
                                    marginBottom: "20px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textShadow: "1px 1px 2px rgba(0,0,0,0.1)",
                                    fontWeight: "bold",
                                }}
                            >
                                Template Details
                            </Typography>

                            <Box
                                sx={{
                                    borderRadius: "8px",
                                    padding: "5px",
                                    marginBottom: "20px",
                                    backgroundColor: "#E9EAEC",
                                }}
                            >
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#24a0ed",
                                        marginBottom: "30px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textShadow: "1px 1px 2px rgba(0,0,0,0.1)",
                                        fontWeight: "bold",
                                    }}
                                >
                                    SUBJECT:
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: "#004d40",
                                        fontWeight: "bold",
                                        marginBottom: "10px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {selectedTemplate.subject}
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    borderRadius: "8px",
                                    padding: "40px",
                                    backgroundColor: "#E9EAEC",
                                }}
                            >
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#24a0ed",
                                        marginBottom: "30px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textShadow: "1px 1px 2px rgba(0,0,0,0.1)",
                                        fontWeight: "bold",
                                    }}
                                >
                                    BODY:
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: "bold",
                                        marginBottom: "30px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {selectedTemplate.content}
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                    {/* </Grid> */}

                    {/* Domain Blacklist Section */}
                    <Grid item xs={12} md={6}>
                        <Paper
                            sx={{
                                padding: "20px",
                                border: "1px solid #ddd",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)",
                                borderRadius: "15px",
                                backgroundColor: "#fff",
                                height: "300px", // Set max height for scrollable section
                                overflowY: "auto", // Enable vertical scrolling
                                transform: "translateZ(0)",
                                transition: "transform 0.3s ease-in-out",
                                "&:hover": {
                                    transform: "scale(1.05)",
                                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.19)",
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    color: "#24a0ed",
                                    marginBottom: "10px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                                    fontWeight: "bold",
                                }}
                            >
                                Domain Blacklist
                            </Typography>
                            <List>
                                {domainBlacklist.map((entry, index) => (
                                    <ListItem
                                        key={index}
                                        button
                                        onClick={() => handleDelist("Domain", entry.name)}
                                        sx={{
                                            border: "1px solid #ddd",
                                            borderRadius: "5px",
                                            marginBottom: "2px",
                                            "&:hover": {
                                                backgroundColor: "#e0f2f1", // Hover effect
                                            },
                                        }}
                                    >
                                        <ListItemText primary={entry.name} sx={{ textAlign: "center" }} />
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleDelist("IP", entry.name)}
                                        >
                                            Delist
                                        </Button>
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    </Grid>

                    {/* IP Blacklist Section */}
                    <Grid item xs={12} md={6}>
                        <Paper
                            sx={{
                                padding: "20px",
                                border: "1px solid #ddd",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)",
                                borderRadius: "15px",
                                backgroundColor: "#fff",
                                height: "300px", // Set max height for scrollable section
                                overflowY: "auto", // Enable vertical scrolling
                                transform: "translateZ(0)",
                                transition: "transform 0.3s ease-in-out",
                                "&:hover": {
                                    transform: "scale(1.05)",
                                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.19)",
                                },
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    color: "#24a0ed",
                                    marginBottom: "10px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                                    fontWeight: "bold",
                                }}
                            >
                                IP Blacklist
                            </Typography>
                            <List>
                                {ipBlacklist.map((entry, index) => (
                                    <ListItem
                                        key={index}
                                        button
                                        onClick={() => handleDelist("IP", entry.name)}
                                        sx={{
                                            border: "1px solid #ddd",
                                            borderRadius: "5px",
                                            marginBottom: "2px",
                                            "&:hover": {
                                                backgroundColor: "#e0f2f1", // Hover effect
                                            },
                                        }}
                                    >
                                        <ListItemText primary={entry.name} sx={{ textAlign: "center" }} />
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleDelist("IP", entry.name)}
                                        >
                                            Delist
                                        </Button>
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Paper
                            sx={{
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: 2,
                                padding: "30px",
                                border: "1px solid #ddd",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)",
                                borderRadius: "15px",
                                backgroundColor: "#fff",
                                position: "relative", // Add relative positioning for containing absolute elements
                                transform: "translateZ(0)",
                                transition: "transform 0.3s ease-in-out",
                                "&:hover": {
                                    transform: "scale(1.01)",
                                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.19)",
                                },
                            }}
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    color: "#24a0ed",
                                    marginBottom: "10px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                                    fontWeight: "bold",
                                }}
                            >
                                Pie Chart
                            </Typography>
                            <Pie data={data} options={options} width={150} height={75} />
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Paper
                            sx={{
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: 2,
                                padding: "20px",
                                border: "1px solid #ddd",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)",
                                borderRadius: "15px",
                                backgroundColor: "#fff",
                                position: "relative",
                                transform: "translateZ(0)",
                                transition: "transform 0.3s ease-in-out",
                                "&:hover": {
                                    transform: "scale(1.01)",
                                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.19)",
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 16,
                                    right: 16,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-end",
                                }}
                            >
                                {/* <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                                <Box
                                    sx={{
                                        width: 12,
                                        height: 12,
                                        backgroundColor: "green",
                                        borderRadius: "50%",
                                        mr: 1,
                                    }}
                                />
                                <Typography>Soft Bounce</Typography>
                            </Box> */}
                                {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Box
                                    sx={{
                                        width: 12,
                                        height: 12,
                                        backgroundColor: "purple",
                                        borderRadius: "50%",
                                        mr: 1,
                                    }}
                                />
                                <Typography>Hard Bounce</Typography>
                            </Box> */}
                            </Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    color: "#24a0ed",
                                    marginBottom: "10px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                                    fontWeight: "bold",
                                }}
                            >
                                Line Graph
                            </Typography>
                            <Line data={data2} options={options2} height={300} />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default AuditReport;
