import React from "react";
import { Button } from "@mui/material";

const ButtonComponent = ({ label, onClick, isSelected }) => {
    return (
        <Button
            onClick={onClick}
            variant={isSelected ? "contained" : "outlined"}
            sx={{
                border: isSelected ? 'none' : '2px solid #26a8ea',
                color: isSelected ? 'white' : '#26a8ea',
                fontWeight: 'bold',
                borderRadius: '8px',
                margin: '5px',
                transition: 'background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease',
                padding: '10px 20px',
                backgroundColor: isSelected ? '#26a8ea' : 'transparent',
                '&:hover': {
                    backgroundColor: isSelected ? '#1a6bb3' : '#e6f0ff',
                    borderColor: isSelected ? 'transparent' : '#1a6bb3',
                    color: isSelected ? 'white' : '#1a6bb3',
                }
            }}
        >
            {label}
        </Button>
    );
};

export default ButtonComponent;
