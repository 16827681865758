import React from "react";
import { Box } from "@mui/material";
import ButtonComponent from "./ButtonComponent";

const ButtonGroupComponent = ({ buttons, selectedButton, handleButtonClick }) => {
    return (
        <Box className="button-group-container">
            {buttons.map((button) => (
                <ButtonComponent
                    key={button.label}
                    label={button.label}
                    onClick={() => handleButtonClick(button.value)}
                    isSelected={selectedButton === button.value}
                />
            ))}
        </Box>
    );
};

export default ButtonGroupComponent;
