import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box, Card, Button, TableRow, TableCell, Typography, CircularProgress, Snackbar, Alert } from "@mui/material";
import { Skeleton } from "@mui/material";

import { CardContent } from "@material-ui/core";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#FAFAFA",
        fontSize: "12px",
        fontWeight: "700",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border

    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

function DomainCalculator() {
    const [domainv, setDomainv] = useState("");
    const [volumePD, setVolumePD] = useState("");
    const [Loading, setLoading] = useState(false);
    const [alignment, setAlignment] = React.useState("DV");
    const [DV, setDV] = useState(true);
    const [VPD, setVPD] = useState(false);
    const [dvData, setDvData] = useState({});
    const [vpdData, setVPDdata] = useState({});
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
    const [esp, setEsp] = React.useState("gmail");

    const handleSubmit = (event) => {
        console.log("render");
        getVolume();
    };

    const handleSubmit2 = (event) => {
        console.log("render");
        getVolumePerday();
    };

    const handleChange = (event) => {
        setEsp(event.target.value);
    };

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setErrorSnackbarOpen(false);
    };

    const HandleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
        if (newAlignment === "DV") {
            setEsp("gmail");
            setDV(true);
            setVPD(false);
        } else {
            setEsp("gmail");
            setDV(false);
            setVPD(true);
        }
    };

    function getVolume(e) {
        e.preventDefault();
        setLoading(true);
        console.log(esp);
        if (domainv <= "0") {
            setLoading(false);
            setErrorSnackbarOpen(true);
            setErrorMessage("Domain cannot be zero");
            return;
        } else {
            setTimeout(() => {
                fetch(`${REACT_APP_SERVER_URL}/calcValueDomain?no_of_domain=${domainv}&esp=${esp}`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    method: "GET",
                    credentials: "include",
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        setLoading(false);
                        if (data.status === 200) {
                            setDvData(data.data[0]);
                        } else {
                            setErrorSnackbarOpen(true);
                            setErrorMessage("Something went wrong");
                        }
                    });
            }, 400);
        }
    }

    function getVolumePerday(e) {
        e.preventDefault();
        setLoading(true);
        console.log(esp);
        if (volumePD <= 0) {
            setLoading(false);
            setErrorSnackbarOpen(true);
            setErrorMessage("Volume cannot be zero");
            return;
        } else {
            setTimeout(() => {
                fetch(`${REACT_APP_SERVER_URL}/calcDomain?volume=${volumePD}&esp=${esp}`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    method: "GET",
                    credentials: "include",
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        setLoading(false);
                        if (data.status === 200) {
                            setVPDdata(data.data[0]);
                        } else {
                            setErrorSnackbarOpen(true);
                            setErrorMessage("Something went wrong");
                        }
                    });
            }, 400);
        }
    }

    return (
        <>
            <Box sx={{ textAlign: "center" }}>
                <span style={{ fontSize: "30px", fontWeight: "1200px", lineHeight: "38px" }}> Domain Calculator </span>
                <br />
                <span
                    style={{
                        ftextAlign: "center",
                        fontSize: "18px",
                        color: "rgba(71, 84, 103, 1)",
                        fontWeight: "400px",
                        lineHeight: "24px",
                    }}
                >
                    Want to send out cold email campaigns but worried about the prerequisites involved ? Just enter your
                    desired information below and let this calculator guide you through.
                </span>
            </Box>
            <br />
            <Box sx={{ textAlign: "center" }}>
                <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={HandleChange}
                    aria-label="Platform"
                    sx={{ marginBottom: "20px", gap: "10px" }}
                >
                    <ToggleButton value="DV">Search by domain</ToggleButton>
                    <ToggleButton value="VPD">search by volume</ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Box>
                <p style={{ fontSize: "25px", fontWeight: "400px" }}>Enter Domain</p>
                <p style={{ fontSize: "20px", color: "rgba(71, 84, 103, 1)", fontWeight: "400px", marginTop: "-20px" }}>
                    Trace email delivery to domains without tests,Integrate,run,view-all personalized
                </p>
            </Box>
            {DV && (
                <form onSubmit={handleSubmit}>
                    <Box>
                        <TextField
                            sx={{ marginRight: "30px" }}
                            id="outlined-basic"
                            label="Enter number of domain"
                            variant="outlined"
                            type="number"
                            size="small"
                            onChange={(e) => {
                                setDomainv(e.target.value);
                            }}
                            value={domainv}
                        />
                        <FormControl sx={{ minWidth: 120, marginRight: "20px" }} size="small">
                            <InputLabel id="demo-select-small-label">ESP</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={esp}
                                label="Age"
                                onChange={handleChange}
                            >
                                <MenuItem value={"gmail"}>Gmail</MenuItem>
                                <MenuItem value={"outlook"}>Outlook</MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            type="submit"
                            style={{ backgroundColor: "#004aad", color: "#fff", padding: "6px" }}
                            variant="contained"
                            onClick={getVolume}
                        >
                            calculate
                        </Button>
                        {Loading ? (
                            <Box sx={{ display: "flex", justifyContent: "space-around", marginTop: "50px" }}>
                                <Skeleton variant="rectangular" width={210} height={118} />
                                <Skeleton variant="rectangular" width={210} height={118} />
                                <Skeleton variant="rectangular" width={210} height={118} />
                                <Skeleton variant="rectangular" width={210} height={118} />
                                <Skeleton variant="rectangular" width={210} height={118} />
                            </Box>
                        ) : (
                            Object.keys(dvData).length !== 0 && (
                                <Box sx={{ marginTop: "30px" }}>
                                    <CardContent style={{ display: "flex", marginLeft: "-25px" }}>
                                        {dvData &&
                                            Object.keys(dvData).map((key, index) => (
                                                <Card
                                                    sx={{
                                                        width: "100%",
                                                        padding: "15px",
                                                        margin: "10px",
                                                        border: "6px solid #DCF2F1",
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{ fontSize: "15px", fontWeight: "400px", margin: "5px" }}
                                                    >
                                                        {key}
                                                    </Typography>
                                                    <Typography
                                                        sx={{ fontSize: "24px", fontWeight: "700px", margin: "5px" }}
                                                    >
                                                        {dvData[key]}
                                                    </Typography>
                                                </Card>
                                            ))}
                                    </CardContent>
                                </Box>
                            )
                        )}
                    </Box>
                </form>
            )}
            {VPD && (
                <Box>
                    <form onSubmit={handleSubmit2}>
                        <TextField
                            sx={{ marginRight: "30px" }}
                            id="outlined-basic"
                            label="Enter volume"
                            size="small"
                            variant="outlined"
                            type="number"
                            onChange={(e) => {
                                setVolumePD(e.target.value);
                            }}
                            value={volumePD}
                        />

                        <FormControl sx={{ minWidth: 120, marginRight: "20px" }} size="small">
                            <InputLabel id="demo-select-small-label">ESP</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={esp}
                                label="Age"
                                onChange={handleChange}
                                // onKeyDown={handleKeyDown}
                            >
                                <MenuItem value={"gmail"}>Gmail</MenuItem>
                                <MenuItem value={"outlook"}>Outlook</MenuItem>
                            </Select>
                        </FormControl>
                        <Button type="submit" sx={{ padding: "6px" }} variant="contained" onClick={getVolumePerday}>
                            calculate
                        </Button>
                    </form>
                    {Loading ? (
                        <Box sx={{ display: "flex", justifyContent: "space-around", marginTop: "50px" }}>
                            <Skeleton variant="rectangular" width={300} height={118} />
                            <Skeleton variant="rectangular" width={300} height={118} />
                            <Skeleton variant="rectangular" width={300} height={118} />
                        </Box>
                    ) : (
                        Object.keys(vpdData).length !== 0 && (
                            <Box sx={{ marginTop: "30px" }}>
                                <CardContent style={{ display: "flex" }}>
                                    {vpdData &&
                                        Object.keys(vpdData).map((key, index) => (
                                            <Card
                                                sx={{
                                                    width: "100%",
                                                    padding: "15px",
                                                    margin: "10px",
                                                    border: "6px solid #DCF2F1",
                                                }}
                                            >
                                                <Typography sx={{ fontSize: "20px", margin: "5px" }}>{key}</Typography>
                                                <Typography
                                                    sx={{ fontSize: "24px", fontWeight: "700px", margin: "5px" }}
                                                >
                                                    {vpdData[key]}
                                                </Typography>
                                            </Card>
                                        ))}
                                </CardContent>
                            </Box>
                        )
                    )}
                </Box>
            )}
            <Snackbar open={errorSnackbarOpen} autoHideDuration={2000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default DomainCalculator;
