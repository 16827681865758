import React, { useEffect, useState } from "react";
import { Button, Box, Dialog, Grid, Typography, TextField, IconButton, Alert, Snackbar } from "@mui/material";
import Google from "../../img/icons/google.png";
import Outlook from "../../img/icons/outlook.png";
import ImapSmtp from "../../img/icons/imap_smtp.png";
import CardComponent from "./CardComponent";
import imap_smtp from "../../img/icons/imap_smtp.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ButtonGroupComponent from "./ButtonGroupComponent";
import TableComponent from "./TableComponent";

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

const IntegrationModule = () => {
    const [selectedButton, setSelectedButton] = useState("AutoIPT");
    const [selectedSubButton, setSelectedSubButton] = useState("Add");
    const [imapSmtp, setImapSmtp] = useState(false);
    const [smtpUsername, setSmtpUsername] = useState("");
    const [smtpPassword, setSmtpPassword] = useState("");
    const [smtpHost, setSmtpHost] = useState("");
    const [smtpPort, setSmtpPort] = useState("");
    const [imapHost, setImapHost] = useState("");
    const [imapPort, setImapPort] = useState("");
    const [imapPassword, setImapPassword] = useState("");
    const [ImapDialogBox, setIMAPDialogBox] = useState(false);
    const [smtpDialogBox, setSmtpDialogBox] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [UserIntegratedRecords, setUserIntegratedRecords] = useState([]);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        if (selectedSubButton === "View") {
            handleEventForFetchRecords();
        }
    }, [selectedButton, selectedSubButton]);

    const integrationModuleButtons = [
        { label: "Auto IPT", value: "AutoIPT" },
        { label: "Bcc Id", value: "BccInsights" },
        { label: "Bcc Sender", value: "BccSender" },
    ];

    const handleSuccessSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSuccessSnackbarOpen(false);
    };

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setErrorSnackbarOpen(false);
    };

    function handleclose() {
        setImapSmtp(false);
        setSmtpDialogBox(false);
        setIMAPDialogBox(false);
    }

    function handleOpenSmtp() {
        setImapSmtp(true);
        setSmtpDialogBox(true);
    }

    const handleButtonClick = (buttonType) => {
        setSelectedButton(buttonType);
    };

    const handleSubButtonClick = (buttonType) => {
        if (buttonType === "View") {
            setSelectedSubButton(buttonType);
            handleEventForFetchRecords();
        }
        setSelectedSubButton(buttonType);
    };

    const handleCardClick = (cardType) => {
        switch (cardType) {
            case "google":
                googleUserAdd();
                break;
            case "outlook":
                outlookUserAdd();
                console.log("Calling Outlook function");
                break;
            case "imapSmtp":
                handleOpenSmtp();
                break;

            default:
                console.log("Invalid value pressed");
        }
    };

    const googleUserAdd = async () => {
        try {
            const response = await fetch(`${REACT_APP_SERVER_URL}/auth/google/?appType=${selectedButton}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "GET",
                credentials: "include",
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            window.location.href = data.data;
        } catch (error) {
            console.error("Error during Google authentication:", error);
        }
    };

    async function outlookUserAdd() {
        try {
            try {
                const response = await fetch(`${REACT_APP_SERVER_URL}/auth/outlook/?appType=${selectedButton}`, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    method: "GET",
                    credentials: "include",
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                window.location.href = data.data;
            } catch (error) {
                console.error("Error during Google authentication:", error);
            }
        } catch (error) {
            console.log(error, "error is coming...");
        }
    }

    function handleSmtpEvent() {
        try {
            fetch(`${REACT_APP_SERVER_URL}/auth/Smtp/?appType=${selectedButton}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                    mail: smtpUsername,
                    credentials: smtpPassword,
                    smtp_host: smtpHost,
                    smtp_port: smtpPort,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log("data getting from here", data);
                    if (data.success) {
                        setSuccessSnackbarOpen(true);
                        setSuccessMessage(data.message);
                        setSmtpDialogBox(false);
                        setIMAPDialogBox(true);
                        return;
                    }
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.message);
                });
        } catch (error) {
            console.error(error, "getting the error...");
        }
    }

    function handleImapEvent() {
        try {
            fetch(`${REACT_APP_SERVER_URL}/auth/Imap/?appType=${selectedButton}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                    mail: smtpUsername,
                    imap_port: imapPort,
                    imap_host: imapHost,
                    credentials: smtpPassword,
                    smtp_host: smtpHost,
                    smtp_port: smtpPort,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log("data getting from here", data);
                    if (data.success) {
                        setSuccessSnackbarOpen(true);
                        setSuccessMessage(data.message);
                        setImapSmtp(false);
                        setSmtpDialogBox(false);
                        setIMAPDialogBox(false);
                        return;
                    }
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.message);
                });
        } catch (error) {
            console.error(error, "getting the error...");
        }
    }

    async function handleEventForFetchRecords() {
        try {
            await fetch(`${REACT_APP_SERVER_URL}/integration/fetchRecords/?appType=${selectedButton}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "GET",
                credentials: "include",
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log(data, "getting the fetchedData...");
                    if (data.success) {
                        setSuccessSnackbarOpen(true);
                        setSuccessMessage(data.message);
                        setColumns(
                            Object.keys(data.data[0]).map((key) => ({
                                id: key,
                                label: key.charAt(0).toUpperCase() + key.slice(1),
                            }))
                        );
                        setUserIntegratedRecords(data.data);
                        return;
                    }
                    setErrorSnackbarOpen(true);
                    setErrorMessage(data.message);
                });
        } catch (error) {
            console.error(`Getting the error:`, error);
        }
    }

    return (
        <>
            <Typography variant="h6" component="div" className="integration-card-title">
                Select the Module where you want to add your IDs!
            </Typography>
            <ButtonGroupComponent
                buttons={integrationModuleButtons}
                selectedButton={selectedButton}
                handleButtonClick={handleButtonClick}
            />
            <div>
                <hr />
            </div>

            <Box display="flex" justifyContent="space-between">
                <Button
                    onClick={() => handleSubButtonClick("Add")}
                    variant={selectedSubButton === "Add" ? "contained" : "outlined"}
                >
                    Add
                </Button>
                <Button
                    variant={selectedSubButton === "View" ? "contained" : "outlined"}
                    onClick={() => handleSubButtonClick("View")}
                >
                    View
                </Button>
            </Box>

            {selectedSubButton === "View" ? (
                <Box>{UserIntegratedRecords && <TableComponent columns={columns} rows={UserIntegratedRecords} />}</Box>
            ) : (
                <>
                    {!imapSmtp ? (
                        <div>
                            <Box display="flex" justifyContent="space-evenly">
                                <CardComponent
                                    title="Google/Gsuite"
                                    typography="Add Your Google / Gsuite accounts here."
                                    image={Google}
                                    type="google"
                                    onCardClick={handleCardClick}
                                />
                                <CardComponent
                                    title="OUTLOOK / M365"
                                    typography="Add Your Outlook / M365 accounts here."
                                    image={Outlook}
                                    type="outlook"
                                    onCardClick={handleCardClick}
                                />
                                <CardComponent
                                    title="IMAP/SMTP"
                                    typography="Add Your IMAP / SMTP accounts here."
                                    image={ImapSmtp}
                                    type="imapSmtp"
                                    onCardClick={handleCardClick}
                                />
                            </Box>
                        </div>
                    ) : (
                        <Box sx={{ my: 3 }}>
                            {imapSmtp && smtpDialogBox && (
                                <Dialog
                                    open={smtpDialogBox}
                                    PaperProps={{
                                        style: {
                                            borderRadius: "20px",
                                            padding: "24px",
                                        },
                                    }}
                                >
                                    <>
                                        <Box sx={{ mb: 2 }}>
                                            <Button
                                                sx={{
                                                    py: 1,
                                                    color: "#481BEB",
                                                    borderColor: "#E7E7E7",
                                                    fontSize: "12px",
                                                    fontFamily: "'Inter', sans-serif",
                                                    "&:hover": {
                                                        borderColor: "#E7E7E7",
                                                    },
                                                }}
                                                variant="outlined"
                                                onClick={handleclose}
                                            >
                                                Cancel
                                            </Button>
                                        </Box>
                                        <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
                                            <img
                                                src={imap_smtp}
                                                alt="imap_smtp"
                                                style={{ float: "left", width: "25px" }}
                                            />
                                            <Box sx={{ ml: 2 }}>
                                                <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                                                    Connect to
                                                </Typography>
                                                <Typography>SMTP</Typography>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <TextField
                                                fullWidth
                                                label="Username"
                                                required
                                                value={smtpUsername}
                                                onChange={(e) => setSmtpUsername(e.target.value)}
                                                sx={{ mb: 2 }}
                                            />
                                            <TextField
                                                fullWidth
                                                type="password"
                                                label="SMTP Password"
                                                required
                                                value={smtpPassword}
                                                onChange={(e) => setSmtpPassword(e.target.value)}
                                                sx={{ mb: 2 }}
                                            />
                                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        required
                                                        label="SMTP Host"
                                                        fullWidth
                                                        value={smtpHost}
                                                        onChange={(e) => setSmtpHost(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        required
                                                        label="SMTP Port"
                                                        type="number"
                                                        fullWidth
                                                        value={smtpPort}
                                                        onChange={(e) => setSmtpPort(e.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Button
                                                    disabled={!smtpUsername || !smtpPassword || !smtpHost || !smtpPort}
                                                    onClick={handleSmtpEvent}
                                                    variant="contained"
                                                    sx={{ textTransform: "none", boxShadow: "none" }}
                                                >
                                                    Next
                                                </Button>
                                            </Box>
                                        </Box>
                                    </>
                                </Dialog>
                            )}
                            {imapSmtp && ImapDialogBox && (
                                <Dialog
                                    open={ImapDialogBox}
                                    PaperProps={{
                                        style: {
                                            borderRadius: "20px",
                                            padding: "24px",
                                        },
                                    }}
                                >
                                    <Box sx={{ mb: 2 }}>
                                        <IconButton size="small" onClick={handleclose}>
                                            <ArrowBackIcon fontSize="small" />
                                        </IconButton>
                                    </Box>
                                    <>
                                        <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
                                            <img
                                                src={imap_smtp}
                                                alt="imap_smtp"
                                                style={{ float: "left", width: "25px" }}
                                            />
                                            <Box sx={{ ml: 2 }}>
                                                <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                                                    Connect to
                                                </Typography>
                                                <Typography>IMAP</Typography>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <TextField
                                                fullWidth
                                                label="IMAP Password"
                                                type="password"
                                                required
                                                value={imapPassword}
                                                onChange={(e) => setImapPassword(e.target.value)}
                                                sx={{ mb: 2 }}
                                            />
                                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        required
                                                        label="IMAP Host"
                                                        fullWidth
                                                        value={imapHost}
                                                        onChange={(e) => setImapHost(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        required
                                                        label="IMAP Port"
                                                        type="number"
                                                        fullWidth
                                                        value={imapPort}
                                                        onChange={(e) => setImapPort(e.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Button
                                                    onClick={handleImapEvent}
                                                    variant="contained"
                                                    sx={{ textTransform: "none", boxShadow: "none" }}
                                                >
                                                    Next
                                                </Button>
                                            </Box>
                                        </Box>
                                    </>
                                </Dialog>
                            )}
                        </Box>
                    )}
                </>
            )}
            <Snackbar open={successSnackbarOpen} autoHideDuration={2000} onClose={handleSuccessSnackbarClose}>
                <Alert onClose={handleSuccessSnackbarClose} variant="filled" severity="success" sx={{ width: "100%" }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={errorSnackbarOpen} autoHideDuration={2000} onClose={handleErrorSnackbarClose}>
                <Alert onClose={handleErrorSnackbarClose} variant="filled" severity="error" sx={{ width: "100%" }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default IntegrationModule;
